import {
  Stack,
  SxProps,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonProps,
} from '@mui/material';
import type { ReactNode } from 'react';
import MaybeTooltip, { MaybeTooltipProps } from '../utils/MaybeTooltip';
import { mixinSx } from '@watershed/style/styleUtils';

// Unfortunately, ToggleButtonGroup uses React.children.map instead of context
// so wrapping with Tooltip breaks ToggleButton functionality. This is a
// workaround.
function MaybeTooltipToggleButton({
  children,
  title,
  ...props
}: Pick<MaybeTooltipProps, 'title'> & Omit<ToggleButtonProps, 'title'>) {
  return (
    <MaybeTooltip title={title}>
      <ToggleButton {...props}>{children}</ToggleButton>
    </MaybeTooltip>
  );
}

function getBaseButtonSx({
  orientation,
  noPadding = false,
  iconOnly = false,
}: {
  orientation: 'horizontal' | 'vertical';
  noPadding?: boolean;
  iconOnly?: boolean;
}): SxProps<Theme> {
  return (theme) => {
    return {
      appearance: 'none',
      border: 'none',
      outline: 'none',
      padding: noPadding
        ? 0
        : orientation === 'vertical'
          ? '12px'
          : iconOnly
            ? '3px 5px'
            : '3px 12px',
      height: 'auto',
      transition: theme.transitions.create(
        ['background-color', 'box-shadow', 'color'],
        { duration: theme.transitions.duration.shortest }
      ),
      zIndex: 0,
      whiteSpace: 'nowrap',

      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
      fontSize: theme.typography.body3.fontSize,
      fontWeight: 600,
      letterSpacing: 'inherit',
      textTransform: 'none',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(0.75),

      '&.MuiToggleButton-root': {
        margin: 0,
        flex: 1,
        borderRadius: `4.5px`,
        border: '1px solid',
        borderColor: 'transparent',
        // In horizontal mode, keep a consistent height for row displays
        height: orientation === 'vertical' ? 'auto' : '28px',

        '&.Mui-disabled': {
          color: theme.palette.secondary.main,
          borderColor: 'transparent',
        },

        '&.MuiToggleButtonGroup-grouped:not(:last-of-type), &.MuiToggleButtonGroup-grouped:not(:first-of-type)':
          {
            // Override default MUI behavior to flatten adjacent corners
            borderRadius: `4.5px`,
          },

        '&.Mui-selected, &.Mui-selected:hover': {
          borderColor: theme.palette.border,
          backgroundColor: theme.palette.background.paper,

          '&.MuiToggleButtonGroup-grouped:not(:last-of-type), &.MuiToggleButtonGroup-grouped:not(:first-of-type)':
            {
              // Override default MUI behavior to clear adjacent borders
              borderColor: theme.palette.border,
            },
        },
      },
    };
  };
}

export type SegmentedControlProps<T extends string | boolean | number> = {
  selectedOptionId: T;
  options: ReadonlyArray<{
    id: T;
    title?: ReactNode;
    icon?: ReactNode;
    tooltip?: MaybeTooltipProps['title'];
    disabled?: boolean;
    'data-testid'?: string;
  }>;
  onChange: (optionId: T) => void;
  // noPadding is helpful when you want to include <Tooltip> in each
  // option.title. In that case, set your own padding so the <Tooltip>
  // activates whenever you hover any part of the button (otherwise, there'd
  // be a dead-zone around the edges).
  noPadding?: boolean;
  sx?: SxProps<Theme>;
  selectedButtonSx?: SxProps<Theme>;
  buttonSx?: SxProps<Theme>;
  disabled?: boolean;
  orientation?: 'horizontal' | 'vertical';
};

export default function SegmentedControl<T extends string | boolean | number>({
  buttonSx,
  disabled,
  noPadding,
  onChange,
  options,
  orientation = 'horizontal',
  selectedButtonSx,
  selectedOptionId,
  sx,
}: SegmentedControlProps<T>) {
  return (
    <ToggleButtonGroup
      value={selectedOptionId}
      exclusive
      onChange={(evt, value) => {
        // `value` is null when the already-checked toggle was clicked again
        if (value !== null) {
          onChange(value);
        }
      }}
      sx={mixinSx(
        {
          backgroundColor: 'secondary.light',
          borderRadius: '6px',
          padding: '1px',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid',
          borderColor: (theme) => theme.palette.border,
        },
        sx
      )}
      data-html2canvas-ignore
      disabled={disabled}
      orientation={orientation}
    >
      {options.map((option) => (
        <MaybeTooltipToggleButton
          key={option.id.toString()}
          title={option.tooltip}
          value={option.id}
          selected={option.id === selectedOptionId}
          disableRipple
          disabled={option.disabled}
          data-testid={option['data-testid']}
          sx={mixinSx(
            getBaseButtonSx({
              orientation,
              noPadding,
              iconOnly: !!option.icon && !option.title,
            }),
            option.id === selectedOptionId
              ? mixinSx(buttonSx, selectedButtonSx)
              : buttonSx
          )}
        >
          <Stack direction="row" gap={1} alignItems="center">
            {option.icon}
            {option.title}
          </Stack>
        </MaybeTooltipToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
