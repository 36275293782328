import defaults from 'lodash/defaults';
import {
  makeCustomErrorInvariant,
  makeRethrower,
  CustomErrorInvariant,
  WatershedError,
  WatershedErrorOptions,
} from './WatershedError';

export class NetworkError extends WatershedError {
  constructor(message?: string, options?: WatershedErrorOptions) {
    const optionsWithDefaults = defaults(options, {
      retryable: true,
    });

    super('NETWORK', message, optionsWithDefaults);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NetworkError);
    }
    Object.setPrototypeOf(this, NetworkError.prototype);
  }

  static invariant: CustomErrorInvariant =
    makeCustomErrorInvariant(NetworkError);
  static wrapAndRethrow = makeRethrower(NetworkError);
}
