import defaults from 'lodash/defaults';
import {
  CustomErrorInvariant,
  makeCustomErrorInvariant,
  makeRethrower,
  WatershedError,
  WatershedErrorOptions,
} from './WatershedError';

export class TooManyRequestsError extends WatershedError {
  status = 429;
  constructor(message?: string, options?: WatershedErrorOptions) {
    const optionsWithDefaults = defaults(options, {
      retryable: true,
    });

    super('TOO_MANY_REQUESTS', message, optionsWithDefaults);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, TooManyRequestsError);
    }
    Object.setPrototypeOf(this, TooManyRequestsError.prototype);
  }

  static invariant: CustomErrorInvariant =
    makeCustomErrorInvariant(TooManyRequestsError);
  static wrapAndRethrow = makeRethrower(TooManyRequestsError);
}
