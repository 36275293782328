import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const CopyIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M8.6 1c-.725 0-1.367.353-1.807.793C6.353 2.233 6 2.875 6 3.6v12.8c0 .725.353 1.367.793 1.807.44.44 1.082.793 1.807.793h9.8c.725 0 1.367-.352 1.807-.793.44-.44.793-1.082.793-1.807V6.5a1 1 0 0 0-.293-.707l-4.5-4.5A1 1 0 0 0 15.5 1H8.6ZM14 3H8.6c-.075 0-.233.047-.393.207-.16.16-.207.318-.207.393v12.8c0 .075.047.233.207.393.16.16.318.207.393.207h9.8c.075 0 .233-.047.393-.207.16-.16.207-.318.207-.393V8h-4a1 1 0 0 1-1-1V3Zm4.086 3H16V3.914L18.086 6Z"
      clipRule="evenodd"
    />
    <path d="M4 7.6a1 1 0 1 0-2 0v12.8c0 .725.353 1.367.793 1.807.44.44 1.082.793 1.807.793h9.8a1 1 0 1 0 0-2H4.6c-.075 0-.233-.047-.393-.207-.16-.16-.207-.318-.207-.393V7.6Z" />
  </Icon>
));
export default CopyIcon;
