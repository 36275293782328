import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const GroupByIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M2.879 2.879A3 3 0 0 1 5 2a1 1 0 0 1 0 2 1 1 0 0 0-1 1 1 1 0 0 1-2 0 3 3 0 0 1 .879-2.121ZM18 3a1 1 0 0 1 1-1 3 3 0 0 1 3 3 1 1 0 1 1-2 0 1 1 0 0 0-1-1 1 1 0 0 1-1-1Zm3 15a1 1 0 0 1 1 1 3 3 0 0 1-3 3 1 1 0 1 1 0-2 1 1 0 0 0 1-1 1 1 0 0 1 1-1ZM3 18a1 1 0 0 1 1 1 1 1 0 0 0 1 1 1 1 0 1 1 0 2 3 3 0 0 1-3-3 1 1 0 0 1 1-1ZM8 3a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Zm0 18a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Zm5-18a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2h-1a1 1 0 0 1-1-1Zm0 18a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2h-1a1 1 0 0 1-1-1ZM3 8a1 1 0 0 1 1 1v1a1 1 0 1 1-2 0V9a1 1 0 0 1 1-1Zm18 0a1 1 0 0 1 1 1v1a1 1 0 1 1-2 0V9a1 1 0 0 1 1-1ZM3 13a1 1 0 0 1 1 1v1a1 1 0 1 1-2 0v-1a1 1 0 0 1 1-1Zm18 0a1 1 0 0 1 1 1v1a1 1 0 1 1-2 0v-1a1 1 0 0 1 1-1Z"
      clipRule="evenodd"
    />
  </Icon>
));
export default GroupByIcon;
