import {
  GQMethodologyCustomizationColumnDisplayFormat,
  GQMethodologyCustomizationDisplayConfigIcon,
  GQMethodologyCustomizationMissingDataSourceConfigImage,
} from '../generated/graphql';
import {
  ValueMappingConfig,
  ValueMappingContextColumn,
  ValueMappingOutputColumn,
} from '../mappings/valueMappingTypes';
import type { ReferenceDataSchemaDefinitionField } from '../referenceData/ReferenceDataSchemaInterface';

export const EF_ACTIVE_TAB_QUERY_PARAM = 'activeTab';
export const EF_CUSTOMIZATION_TYPE_QUERY_PARAM = 'customizationTypeId';
export const EF_CUSTOMIZATION_REFERRER_VALUE = 'emissions-factors';

export type EmissionsFactorsTab = (typeof EF_TABS)[number];

export const EF_TAB_LIBRARY = 'ef-library';
export const EF_TAB_MAPPING = 'mapping';
export const EF_TABS = [EF_TAB_LIBRARY, EF_TAB_MAPPING] as const;

const CUSTOMIZATION_TYPE_IDS_FOR_UPLOADS = [
  'electricity_production',
  'electricity_t_d_wtt',
  'electricity_wtt',
] as const;
export type MethodologyCustomizationTypeIdForUploads =
  (typeof CUSTOMIZATION_TYPE_IDS_FOR_UPLOADS)[number];

export const CUSTOMIZATION_TYPE_IDS = [
  ...CUSTOMIZATION_TYPE_IDS_FOR_UPLOADS,
  'supplier_spend',
  'supplier_activity',
] as const;
export type MethodologyCustomizationTypeId =
  (typeof CUSTOMIZATION_TYPE_IDS)[number];

export const CUSTOMIZATION_MAPPING_CONFIG_IDS = [
  'electricity_production_ef_building',
  'electricity_t_d_wtt_building',
  'electricity_wtt_ef_building',
] as const;
export type MethodologyCustomizationMappingConfigId =
  (typeof CUSTOMIZATION_MAPPING_CONFIG_IDS)[number];

export const isEmissionsFactorsTab = (
  slug: string | null
): slug is EmissionsFactorsTab =>
  !!slug && (EF_TABS as ReadonlyArray<string>).includes(slug);

export const isMethodologyCustomizationTypeId = (
  slug: string | null
): slug is MethodologyCustomizationTypeId =>
  !!slug &&
  CUSTOMIZATION_TYPE_IDS.includes(
    slug as (typeof CUSTOMIZATION_TYPE_IDS)[number]
  );

export const isMethodologyCustomizationTypeIdForUploads = (
  slug: string | null
): slug is MethodologyCustomizationTypeIdForUploads =>
  !!slug &&
  CUSTOMIZATION_TYPE_IDS_FOR_UPLOADS.includes(
    slug as (typeof CUSTOMIZATION_TYPE_IDS_FOR_UPLOADS)[number]
  );

export const isMethodologyCustomizationValueMappingConfigId = (
  slug: string | null
): slug is MethodologyCustomizationMappingConfigId =>
  !!slug && CUSTOMIZATION_MAPPING_CONFIG_IDS.includes(slug as any);

export const METHODOLOGY_CUSTOMIZATION_OUTPUT_COLUMN = 'ref_data_pointer';
export const METHODOLOGY_CUSTOMIZATION_OUTPUT_COLUMN_LABEL = 'Emissions factor';

export const EF_NAME_COL = 'ef_name';
export const EF_ID_COL = 'ef_id';
export const EF_KGCO2E_COL = 'kgco2e';
export const EF_UNIT_COL = 'unit';
export const EF_CITATION_SLUG_COL = 'citation_slug';
export const EF_CITATION_TITLE_COL = 'citation_title';
export const EF_CITATION_NOTES_COL = 'citation_notes';
export const EF_CITATION_URL_COL = 'citation_url';

export const METHODOLOGY_CUSTOMIZATION_SPECIAL_COLUMN_NAME_BART_INSTANCE_IDS =
  'bart_instance_ids';
export const METHODOLOGY_CUSTOMIZATION_SPECIAL_COLUMN_NAME_START_DATE =
  'start_date';
export const METHODOLOGY_CUSTOMIZATION_SPECIAL_COLUMN_NAME_END_DATE =
  'end_date';
export const METHODOLOGY_CUSTOMIZATION_SPECIAL_COLUMN_NAME_ACTIVITY_START_DATE =
  'activity_start_date';
export const METHODOLOGY_CUSTOMIZATION_SPECIAL_COLUMN_NAME_ACTIVITY_END_DATE =
  'activity_end_date';
export const METHODOLOGY_CUSTOMIZATION_SPECIAL_COLUMN_NAME_IS_SUPPLIER_SPECIFIC =
  'is_supplier_specific';
export const METHODOLOGY_CUSTOMIZATION_SPECIAL_COLUMN_NAME_BUILDING_IDENTIFIER =
  'building_identifier';
export const METHODOLOGY_CUSTOMIZATION_SPECIAL_COLUMN_NAME_BUILDING_NAME =
  'building_name';
export const METHODOLOGY_CUSTOMIZATION_SPECIAL_COLUMN_NAME_SUPPLIER_NAME =
  'supplier';

// copied from hardcodedValueMappingConfigs.ts to prevent circular dependency
const VALUE_MAPPING_SPECIAL_COLUMN_NAME_FLAGGED_ROW = 'row_flag';

export const CUSTOMER_UPLOADED_COLUMNS_SCHEMA: Record<
  string,
  ReferenceDataSchemaDefinitionField
> = {
  [EF_NAME_COL]: {
    description: 'Emissions factor name',
    type: 'string',
  },
  [EF_KGCO2E_COL]: {
    type: 'number',
    description: 'KgCO₂e per unit',
    unitColumn: EF_UNIT_COL,
    context: {
      citationColumn: EF_CITATION_SLUG_COL,
      externalDescriptionColumn: EF_NAME_COL,
    },
  },
  [EF_UNIT_COL]: {
    type: 'string',
    enum: [], // This should be overridden in getCustomerUploadColumnProperties
    description: 'Unit column for KgCO₂e per unit',
  },
  [EF_CITATION_SLUG_COL]: {
    type: 'string',
    description: 'Citation slug',
  },
  [METHODOLOGY_CUSTOMIZATION_SPECIAL_COLUMN_NAME_START_DATE]: {
    type: 'string',
    format: 'date',
    description: 'Start date',
  },
  [METHODOLOGY_CUSTOMIZATION_SPECIAL_COLUMN_NAME_END_DATE]: {
    type: 'string',
    format: 'date',
    description: 'End date',
  },
  [METHODOLOGY_CUSTOMIZATION_SPECIAL_COLUMN_NAME_IS_SUPPLIER_SPECIFIC]: {
    type: 'boolean',
    description:
      'Whether the emissions factor uses data obtained from suppliers or value chain partners (this is used for reporting purposes).',
  },
};

export const CUSTOMER_UPLOADED_COLUMNS = Object.keys(
  CUSTOMER_UPLOADED_COLUMNS_SCHEMA
);

const ACTIVITY_DATE_CONTEXT_COLUMNS: Record<string, ValueMappingContextColumn> =
  {
    [METHODOLOGY_CUSTOMIZATION_SPECIAL_COLUMN_NAME_ACTIVITY_START_DATE]: {
      type: 'string',
      format: 'date',
      description:
        'The earliest start date for the activity data rows associated with this mapping input',
      isMinimum: true,
    },
    [METHODOLOGY_CUSTOMIZATION_SPECIAL_COLUMN_NAME_ACTIVITY_END_DATE]: {
      type: 'string',
      format: 'date',
      description:
        'The latest end date for the activity data rows associated with this mapping input',
      isMaximum: true,
    },
  };

const BART_INSTANCE_ID_CONTEXT_COLUMN: Record<
  string,
  ValueMappingContextColumn
> = {
  [METHODOLOGY_CUSTOMIZATION_SPECIAL_COLUMN_NAME_BART_INSTANCE_IDS]: {
    type: 'string',
    description: 'The BART instances that the record was imported from.',
    isSet: true,
    isHiddenByDefault: true,
  },
};

export const METHODOLOGY_CUSTOMIZATION_REQUIRED_CONTEXT_COLUMNS: Record<
  string,
  ValueMappingContextColumn
> = {
  ...BART_INSTANCE_ID_CONTEXT_COLUMN,
  ...ACTIVITY_DATE_CONTEXT_COLUMNS,
};

export const METHODOLOGY_CUSTOMIZATION_REQUIRED_OUTPUT_COLUMNS: Record<
  string,
  ValueMappingOutputColumn
> = {
  [METHODOLOGY_CUSTOMIZATION_OUTPUT_COLUMN]: {
    type: 'string',
    description: 'The emissions factor to map to',
    isRequiredForPublish: false,
  },
  [VALUE_MAPPING_SPECIAL_COLUMN_NAME_FLAGGED_ROW]: {
    type: 'string', // enum
    description: 'Row is marked for further work or review.',
    isHiddenByDefault: true,
  },
};

export const DEFAULT_METHODOLOGY_CUSTOMIZATION_MAPPING_START_DATE =
  '2010-01-01';
export const DEFAULT_METHODOLOGY_CUSTOMIZATION_MAPPING_END_DATE = '2030-01-01';

export type ColumnDisplayFormat = Pick<
  GQMethodologyCustomizationColumnDisplayFormat,
  'parquetColumnName' | 'displayName'
>;

// Defines what shows up when there's no data to map from (there's text and a CTA)
type MethodologyCustomizationMissingSourceDataConfig = {
  message: string; // used for "no data exists at all"
  buttonLabel: string;
  imageName: GQMethodologyCustomizationMissingDataSourceConfigImage; // a simple enum of supported image names
};

// Defines a related group of mappings (similar to a dataset but distinct).
// They're often displayed in groups in the frontend.
export type MethodologyCustomizationGroupName = 'Buildings' | 'Spend';

export type BusinessActivityMappingInput = {
  businessActivityTypeName: string;
  keyColumnToBartColumn: Record<string, string>; // mapping key column -> BART column
  contextColumnToBartColumn: Record<string, string>; // mapping context column -> BART column
};

// Not the same as ValueMappingConfig, contains additional MC-specific information
type MethodologyCustomizationMappingPageConfig = {
  id: MethodologyCustomizationMappingConfigId;
  groupName: MethodologyCustomizationGroupName;
  missingSourceDataConfig: MethodologyCustomizationMissingSourceDataConfig;
  customizationTypeId: MethodologyCustomizationTypeId; // the customization type this mapping config belongs to
  businessActivityMappingInputs: Array<BusinessActivityMappingInput>; // inputs from BART to seed mappings
};

export type MethodologyCustomizationMappingConfig = ValueMappingConfig &
  MethodologyCustomizationMappingPageConfig;

// Configuration for the "type" of reference data being customized, used for both the display of library page and
// the mapping page
export type MethodologyCustomizationTypeConfig = {
  id: MethodologyCustomizationTypeId; // Concept of an EF/assumption
  displayName: string;
  description: string;
  iconName: GQMethodologyCustomizationDisplayConfigIcon;
  globalReferenceDataSources: Array<string>; // source:version / global/canonical/watershedManaged?
  quantityColumnName: string;
  displayColumns: Array<ColumnDisplayFormat>; // ordering matters here, and if a column name is not included here, we don't show it to the user
  globalColumnsToCustomerUploadedColumns: Record<string, string>; // mapping customer uploaded column name to global column name
};

const COMMON_COLUMN_FORMATS: { [key: string]: ColumnDisplayFormat } = {
  ef_name: {
    parquetColumnName: 'ef_name',
    displayName: 'Emissions factor name',
  },
  kgco2e: {
    parquetColumnName: 'kgco2e',
    displayName: 'KgCO₂e per unit',
  },
  unit: {
    parquetColumnName: 'unit',
    displayName: 'Unit',
  },
  start_date: {
    parquetColumnName: 'start_date',
    displayName: 'Start date',
  },
  end_date: {
    parquetColumnName: 'end_date',
    displayName: 'End date',
  },
  is_electricity_market_ef: {
    parquetColumnName: 'is_electricity_market_ef',
    displayName: 'Is market-based',
  },
  vendor_entity: {
    parquetColumnName: 'vendor_entity',
    displayName: 'Vendor',
  },
  vendor_subentity: {
    parquetColumnName: 'vendor_subentity',
    displayName: 'Vendor subentity',
  },
  location_country: {
    parquetColumnName: 'location_country',
    displayName: 'Country',
  },
  location_subdivision: {
    parquetColumnName: 'location_subdivision',
    displayName: 'Subdivision',
  },
  location_grid: {
    parquetColumnName: 'location_grid',
    displayName: 'Grid',
  },
};

export const SHARED_COLUMN_FORMATS = [
  COMMON_COLUMN_FORMATS.ef_name,
  COMMON_COLUMN_FORMATS.start_date,
  COMMON_COLUMN_FORMATS.end_date,
  COMMON_COLUMN_FORMATS.kgco2e,
  COMMON_COLUMN_FORMATS.unit,
];

const DEFAULT_GLOBAL_TO_CUSTOMER_COLUMNS = {
  [COMMON_COLUMN_FORMATS.ef_name.parquetColumnName]: EF_NAME_COL,
  [COMMON_COLUMN_FORMATS.kgco2e.parquetColumnName]: EF_KGCO2E_COL,
  [COMMON_COLUMN_FORMATS.unit.parquetColumnName]: EF_UNIT_COL,
  [COMMON_COLUMN_FORMATS.start_date.parquetColumnName]:
    METHODOLOGY_CUSTOMIZATION_SPECIAL_COLUMN_NAME_START_DATE,
  [COMMON_COLUMN_FORMATS.end_date.parquetColumnName]:
    METHODOLOGY_CUSTOMIZATION_SPECIAL_COLUMN_NAME_END_DATE,
  // We want this column to show up in CSV export, but not in the user-facing table
  [METHODOLOGY_CUSTOMIZATION_SPECIAL_COLUMN_NAME_IS_SUPPLIER_SPECIFIC]:
    METHODOLOGY_CUSTOMIZATION_SPECIAL_COLUMN_NAME_IS_SUPPLIER_SPECIFIC,
};

const FACILTIIES_MISSING_SOURCE_CONFIG: MethodologyCustomizationMissingSourceDataConfig =
  {
    message:
      'Approve your facilities to enable customization of emissions factors',
    buttonLabel: 'Approve facilities',
    imageName:
      GQMethodologyCustomizationMissingDataSourceConfigImage.ClimateBuildingBlocks,
  };
// Please use MethodologyCustomizationService.getMethodologyCustomizationTypeConfig,
// do not reference this array directly!
export const METHODOLOGY_CUSTOMIZATION_TYPE_CONFIGS: Array<MethodologyCustomizationTypeConfig> =
  [
    {
      id: 'electricity_production',
      displayName: 'Electricity production',
      description:
        'GHG emissions released directly from electricity production, including burning of fossil fuels.',
      iconName: GQMethodologyCustomizationDisplayConfigIcon.Flash,
      globalReferenceDataSources: [
        'electricity_production_h1_2023:initial_version',
      ],
      quantityColumnName: 'kgco2e',
      displayColumns: [
        ...SHARED_COLUMN_FORMATS,
        COMMON_COLUMN_FORMATS.is_electricity_market_ef,
        {
          parquetColumnName: 'vendor_entity',
          displayName: 'Vendor',
        },
        {
          parquetColumnName: 'vendor_subentity',
          displayName: 'Vendor subentity',
        },
        COMMON_COLUMN_FORMATS.location_country,
        {
          parquetColumnName: 'location_subdivision',
          displayName: 'Subdivision',
        },
        {
          parquetColumnName: 'location_grid',
          displayName: 'Grid',
        },
      ],
      globalColumnsToCustomerUploadedColumns:
        DEFAULT_GLOBAL_TO_CUSTOMER_COLUMNS,
    },
    {
      id: 'electricity_t_d_wtt',
      displayName: 'Electricity T&D WTT',
      description:
        'Well-to-tank (i.e. upstream) GHG emissions associated with electricity loss due to resistance in the grid while being transported',
      iconName: GQMethodologyCustomizationDisplayConfigIcon.Flash,
      globalReferenceDataSources: ['electricity_t_d_wtt:v6'],
      quantityColumnName: 'kgco2e',
      displayColumns: [
        ...SHARED_COLUMN_FORMATS,
        COMMON_COLUMN_FORMATS.location_country,
      ],
      globalColumnsToCustomerUploadedColumns:
        DEFAULT_GLOBAL_TO_CUSTOMER_COLUMNS,
    },
    {
      id: 'electricity_wtt',
      displayName: 'Electricity WTT',
      description:
        'Well-to-tank (i.e. upstream) GHG emissions of the production of energy sources to generate electricity.',
      iconName: GQMethodologyCustomizationDisplayConfigIcon.Flash,

      globalReferenceDataSources: ['electricity_wtt_h1_2023:v9'],
      quantityColumnName: 'kgco2e',
      displayColumns: [
        ...SHARED_COLUMN_FORMATS,
        COMMON_COLUMN_FORMATS.is_electricity_market_ef,
        COMMON_COLUMN_FORMATS.location_country,
      ],
      globalColumnsToCustomerUploadedColumns:
        DEFAULT_GLOBAL_TO_CUSTOMER_COLUMNS,
    },
    {
      id: 'supplier_spend',
      displayName: 'Supplier-specific spend',
      description:
        'Expense-related emissions factors specific to particular suppliers.',
      iconName: GQMethodologyCustomizationDisplayConfigIcon.SupplierEngagement,
      globalReferenceDataSources: [
        'expenditure_supplier_emissions_factors:v2',
        'supplier_survey_expenditure_emissions_factors:with_company_id_as_a_unique_key',
      ],
      quantityColumnName: 'kgco2e',
      displayColumns: [
        ...SHARED_COLUMN_FORMATS,
        {
          parquetColumnName: 'supplier',
          displayName: 'Supplier name',
        },
        {
          parquetColumnName: 'supplier_subentity',
          displayName: 'Supplier subentity name',
        },
      ],
      globalColumnsToCustomerUploadedColumns:
        DEFAULT_GLOBAL_TO_CUSTOMER_COLUMNS,
    },
    {
      id: 'supplier_activity',
      displayName: 'Supplier-specific activity',
      description:
        'Activity-related emissions factors specific to particular suppliers.',
      iconName: GQMethodologyCustomizationDisplayConfigIcon.SupplierEngagement,
      globalReferenceDataSources: [
        'supplier_survey_product_level_emissions_factors_area:initial_version',
        'supplier_survey_product_level_emissions_factors_length:fix_units',
        'supplier_survey_product_level_emissions_factors_mass:with_tonne_kilometer',
        'supplier_survey_product_level_emissions_factors_volume:fix_units',
        'supplier_survey_product_level_emissions_factors_time:fix_units',
        'supplier_survey_product_level_emissions_factors_calendar:fix_units',
        'supplier_survey_product_level_emissions_factors_fueleconomy:fix_units',
        'supplier_survey_product_level_emissions_factors_temperature:fix_units',
        'supplier_survey_product_level_emissions_factors_textile:fix_units',
        'supplier_survey_product_level_emissions_factors_energy:fix_units',
        'supplier_survey_product_level_emissions_factors_power:fix_units',
        'supplier_survey_product_level_emissions_factors_count:fix_units',
        'supplier_survey_product_level_emissions_factors_information:initial_version',
        'supplier_survey_product_level_emissions_factors_force:initial_version',
      ],
      quantityColumnName: 'kgco2e',
      displayColumns: [
        ...SHARED_COLUMN_FORMATS,
        {
          parquetColumnName: 'supplier',
          displayName: 'Supplier name',
        },
        {
          parquetColumnName: 'functional_unit_description',
          displayName: 'Product name',
        },
        {
          parquetColumnName: 'life_cycle_model',
          displayName: 'Life cycle model',
        },
        {
          parquetColumnName: 'third_party_verification_status',
          displayName: 'Third party verification status',
        },
        {
          parquetColumnName: 'methodology_notes',
          displayName: 'Methdology notes',
        },
      ],
      globalColumnsToCustomerUploadedColumns:
        DEFAULT_GLOBAL_TO_CUSTOMER_COLUMNS,
    },
  ];

export const METHODOLOGY_CUSTOMIZATION_MAPPING_PAGE_CONFIGS: Array<MethodologyCustomizationMappingPageConfig> =
  [
    {
      id: 'electricity_production_ef_building',
      customizationTypeId: 'electricity_production',
      groupName: 'Buildings',
      missingSourceDataConfig: FACILTIIES_MISSING_SOURCE_CONFIG,
      businessActivityMappingInputs: [
        {
          businessActivityTypeName: 'building_occupation',
          keyColumnToBartColumn: {
            building_identifier: 'building_unique_identifier',
          },
          contextColumnToBartColumn: {
            building_name: 'building_display_name',
          },
        },
        {
          businessActivityTypeName: 'electricity_consumption',
          keyColumnToBartColumn: {
            building_identifier: 'building_unique_identifier',
          },
          contextColumnToBartColumn: {
            building_name: 'building_display_name',
          },
        },
      ],
    },
    {
      id: 'electricity_t_d_wtt_building',
      customizationTypeId: 'electricity_t_d_wtt',
      groupName: 'Buildings',
      missingSourceDataConfig: FACILTIIES_MISSING_SOURCE_CONFIG,
      businessActivityMappingInputs: [
        {
          businessActivityTypeName: 'building_occupation',
          keyColumnToBartColumn: {
            building_identifier: 'building_unique_identifier',
          },
          contextColumnToBartColumn: {
            building_name: 'building_display_name',
          },
        },
        {
          businessActivityTypeName: 'electricity_consumption',
          keyColumnToBartColumn: {
            building_identifier: 'building_unique_identifier',
          },
          contextColumnToBartColumn: {
            building_name: 'building_display_name',
          },
        },
      ],
    },
    {
      id: 'electricity_wtt_ef_building',
      customizationTypeId: 'electricity_wtt',
      groupName: 'Buildings',
      missingSourceDataConfig: FACILTIIES_MISSING_SOURCE_CONFIG,
      businessActivityMappingInputs: [
        {
          businessActivityTypeName: 'building_occupation',
          keyColumnToBartColumn: {
            building_identifier: 'building_unique_identifier',
          },
          contextColumnToBartColumn: {
            building_name: 'building_display_name',
          },
        },
        {
          businessActivityTypeName: 'electricity_consumption',
          keyColumnToBartColumn: {
            building_identifier: 'building_unique_identifier',
          },
          contextColumnToBartColumn: {
            building_name: 'building_display_name',
          },
        },
      ],
    },
  ];
