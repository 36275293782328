import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const SumIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M5.106 3.553A1 1 0 0 1 6 3h12a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0V5H8l4.8 6.4a1 1 0 0 1 0 1.2L8 19h9v-2a1 1 0 1 1 2 0v3a1 1 0 0 1-1 1H6a1 1 0 0 1-.8-1.6l5.55-7.4L5.2 4.6a1 1 0 0 1-.094-1.047Z"
      clipRule="evenodd"
    />
  </Icon>
));
export default SumIcon;
