import {
  makeCustomErrorInvariant,
  makeRethrower,
  CustomErrorInvariant,
  WatershedError,
  WatershedErrorOptions,
} from './WatershedError';

/**
 * Use Gone if a requested schema version is no longer supported
 */
export class GoneError extends WatershedError {
  status = 410;
  constructor(message?: string, options?: WatershedErrorOptions) {
    super('GONE', message, options);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, GoneError);
    }
    Object.setPrototypeOf(this, GoneError.prototype);
  }

  static invariant: CustomErrorInvariant = makeCustomErrorInvariant(GoneError);
  static wrapAndRethrow = makeRethrower(GoneError);
}
