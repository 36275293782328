import last from 'lodash/last';
import omit from 'lodash/omit';
import isPlainObject from 'lodash/isPlainObject';
import size from 'lodash/size';
import pickBy from 'lodash/pickBy';
import mapValues from 'lodash/mapValues';
import { BadDataError } from '@watershed/errors/BadDataError';
import { BadInputError } from '@watershed/errors/BadInputError';
import { CanonicalSchemaValidationError } from '../canonicalSchemas/validationUtils';
import { DataResponseTooLarge } from '@watershed/errors/DataResponseTooLarge';
import { ForbiddenError } from '@watershed/errors/ForbiddenError';
import { GoneError } from '@watershed/errors/GoneError';
import { MessageLoadingError } from '@watershed/errors/MessageLoadingError';
import { MethodNotAllowedError } from '@watershed/errors/MethodNotAllowedError';
import { NetworkError } from '@watershed/errors/NetworkError';
import { NotFoundError } from '@watershed/errors/NotFoundError';
import { TooManyRequestsError } from '@watershed/errors/TooManyRequestsError';
import { UnauthenticatedError } from '@watershed/errors/UnauthenticatedError';
import { UnexpectedError } from '@watershed/errors/UnexpectedError';
import { UnsupportedMediaTypeError } from '@watershed/errors/UnsupportedMediaTypeError';
import { UserInputError } from '@watershed/errors/UserInputError';

/**
 * The registry of errors that can be reconstituted after being deserialized.
 *
 * Any error missing from this list will be deserialized as a generic SerializableError.
 */
const WatershedErrorPrototypes: Record<string, object> = {
  BadDataError: BadDataError.prototype,
  BadInputError: BadInputError.prototype,
  CanonicalSchemaValidationError: CanonicalSchemaValidationError.prototype,
  DataResponseTooLarge: DataResponseTooLarge.prototype,
  ForbiddenError: ForbiddenError.prototype,
  GoneError: GoneError.prototype,
  MessageLoadingError: MessageLoadingError.prototype,
  MethodNotAllowedError: MethodNotAllowedError.prototype,
  NetworkError: NetworkError.prototype,
  NotFoundError: NotFoundError.prototype,
  TooManyRequestsError: TooManyRequestsError.prototype,
  UnauthenticatedError: UnauthenticatedError.prototype,
  UnexpectedError: UnexpectedError.prototype,
  UnsupportedMediaTypeError: UnsupportedMediaTypeError.prototype,
  UserInputError: UserInputError.prototype,
};

export interface ISerializableError {
  name: string | null;
  message: string;
  code: string | null;
  stackTrace: string | null;
  errorType: string | null;
  details: Record<string, any> | null;
  cause?: ISerializableError | null;
}

function transformDetails(details: Record<string, any>): Record<string, any> {
  const result: Record<string, any> = {};
  for (const [key, value] of Object.entries(details)) {
    if (value instanceof Error) {
      result[key] = makeSerializableError(value);
    } else {
      result[key] = value;
    }
  }
  return result;
}

function extractProps(input: Record<string, any>): ISerializableError {
  const result: ISerializableError = {
    name: input.name ? String(input.name) : null,
    message: input.message ? String(input.message) : 'No message',
    code: input.code ? String(input.code) : null,
    stackTrace:
      input.stackTrace || input.stack
        ? String(input.stackTrace || input.stack)
        : null,
    errorType: input.errorType ? String(input.errorType) : null,
    details: null,
  };

  if (isPlainObject(input.details)) {
    result.details = transformDetails(input.details);
  } else if (input.details != null) {
    result.details =
      input.details !== input.message ? { errorDetails: input.details } : null;
  } else {
    // Add all other enumerable properties to `details`.
    const inferredDetails = omit({ ...input }, Object.keys(result));
    result.details =
      size(inferredDetails) > 0
        ? JSON.parse(JSON.stringify(transformDetails(inferredDetails)))
        : null;
  }

  // If we have a CombinedError from urql, surface details from the network
  // error of the first error in the graphQLErrors array.
  if (input.networkError) {
    return makeSerializableError(input.networkError);
  } else if (input.graphQLErrors?.[0]) {
    const gqlError = input.graphQLErrors[0];
    result.details = {
      ...result.details,
      graphQlPath: gqlError.path,
    };
    if (!result.code && gqlError?.extensions?.code) {
      result.code = gqlError.extensions.code;
    }

    if (!result.stackTrace && gqlError?.extensions?.exception?.stacktrace) {
      result.stackTrace = gqlError.extensions.exception.stacktrace.join('\n');
    }

    result.details = {
      ...omit(gqlError?.extensions, 'code', 'exception'),
      ...result.details,
    };
  }
  if (input.graphQLErrors) {
    // The response doesn't serialize well, and is better debugged in the
    // browser's Network tab.
    delete result?.details?.response;
  }

  if (input.cause) {
    result.cause = makeSerializableError(input.cause);
  }

  return result;
}

/**
 * Transforms an input string, Error, or other object into a standard-format
 * SerializableError. Tries to extract as many details as it can out of the
 * input while remaining true to the SerializableError interface. Errs on the
 * side of dropping information instead of throwing any parsing errors.
 */
export function makeSerializableError(input: string | any): ISerializableError {
  if (typeof input !== 'string') {
    return extractProps(input);
  }

  try {
    return extractProps(JSON.parse(input));
  } catch (error) {
    let message = input;
    let stackTrace = null;

    // If the error message is a Python stack trace, use the last line as the
    // `message` and put the whole trace in `stackTrace`.
    const tracebackMatch = input.match(/^([^\n]*)traceback/i);
    if (tracebackMatch !== null) {
      message = [
        tracebackMatch[1].trim(),
        last(input.trim().split('\n')) ?? input,
      ]
        .filter(Boolean)
        .join(' ')
        .trim();
      stackTrace = input.trim();
    }

    const result: ISerializableError = {
      message,
      name: null,
      code: null,
      stackTrace: null,
      errorType: null,
      details: null,
    };
    if (stackTrace) {
      result.stackTrace = stackTrace;
    }
    return result;
  }
}

/**
 * SerializableError is useful if you need to `throw` and you feel better about
 * throwing a subclass of Error than about throwing a plain JS object.
 */
export class SerializableError extends Error implements ISerializableError {
  code: string | null;
  stackTrace: string | null;
  errorType: string | null;
  details: Record<string, any> | null;
  cause?: ISerializableError | null;

  constructor(input: unknown) {
    const serializable = makeSerializableError(input);
    super(serializable.message);
    this.message = serializable.message;
    this.code = serializable.code;
    this.stackTrace = serializable.stackTrace;
    this.errorType = serializable.errorType;
    this.details = serializable.details;
    if (serializable.name) {
      this.name = serializable.name;
    }
    if (serializable.cause) {
      this.cause = serializable.cause;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, SerializableError);
    }

    const knownErrorPrototype = this.name
      ? WatershedErrorPrototypes[this.name]
      : undefined;
    if (knownErrorPrototype) {
      Object.setPrototypeOf(this, knownErrorPrototype);
    } else {
      Object.setPrototypeOf(this, SerializableError.prototype);
    }
  }

  toJSON(): string {
    return JSON.stringify(makeSerializableError(this));
  }

  toPlainObject(): ISerializableError {
    return makeSerializableError(this);
  }

  clone(): SerializableError {
    return new SerializableError(this);
  }
}

/**
 * Create a plain object from an Error instance. The trick is to capture
 * non-enumerable properties we care about (like `message`). `stack` is not
 * included.
 */
export function plainObjectFromError(error: Error): Record<string, unknown> {
  return {
    ...mapValues(
      pickBy(
        {
          ...error,
          message: error.message,
          name: error.name,
          stack: error.stack,
        },
        (v) => v !== undefined
      ),
      (v) => {
        // Trying to handle `error.graphqlErrors` which is an array of `GraphQLError extends Error`
        if (v instanceof Error) {
          return plainObjectFromError(v);
        }
        if (Array.isArray(v)) {
          return v.map((item) =>
            typeof item === 'object' && item !== null
              ? plainObjectFromError(item)
              : item
          );
        }
        return v;
      }
    ),
  };
}
