/* eslint-disable no-restricted-imports */
import CalloutUiCore, {
  CalloutProps,
} from '@watershed/ui-core/components/Callout';
import useEffectOnce from '@watershed/shared-frontend/hooks/useEffectOnce';
import frontendHoneycomb from '@watershed/shared-frontend/utils/frontendHoneycomb';
import { CustomSpanAttributes } from '@watershed/shared-universal/constants/otelCustomAttributes';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';

export default function Callout(props: CalloutProps) {
  useEffectOnce(() => {
    if (props.variant === 'error' && !props.dangerouslySuppressErrorLogging) {
      void sendErrorEventToHoneycomb();
    }
  });
  return (
    <CalloutUiCore
      {...props}
      dataTest={
        props.variant === 'error' ? TestIds.ErrorStateCallout : undefined
      }
    />
  );
}

async function sendErrorEventToHoneycomb() {
  await frontendHoneycomb.sendEvent({
    name: CustomSpanAttributes.BROWSER_USER_VISIBLE_ERROR_CALLOUT,
  });
}
