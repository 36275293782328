import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const InboxIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M16.76 3H7.24a3.002 3.002 0 0 0-2.685 1.664v.001l-3.44 6.87A.996.996 0 0 0 1 12v6a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3v-6.038a.996.996 0 0 0-.115-.428l-3.44-6.869v-.001A3 3 0 0 0 16.76 3Zm3.62 8-2.724-5.442-.002-.003A1 1 0 0 0 16.76 5H7.24a1 1 0 0 0-.894.555l-.002.003L3.62 11H8a1 1 0 0 1 .832.445L10.535 14h2.93l1.703-2.555A1 1 0 0 1 16 11h4.38ZM3 13v5a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-5h-4.465l-1.703 2.555A1 1 0 0 1 14 16h-4a1 1 0 0 1-.832-.445L7.465 13H3Z"
      clipRule="evenodd"
    />
  </Icon>
));
export default InboxIcon;
