import { ComponentProps, ReactNode } from 'react';
import { Box, Stack, StackProps, Typography } from '@mui/material';
import { IconElement } from '@watershed/icons/Icon';
import { mixinSx } from '@watershed/style/styleUtils';

function BlankSlate({
  variant = 'text',
  size = 'medium',
  icon: IconComponent,
  iconColor,
  title,
  subtitle,
  ...props
}: Omit<StackProps, 'title'> & {
  variant?: 'text' | 'contained';
  size?: 'small' | 'medium';
  icon?: IconElement;
  iconColor?: ComponentProps<IconElement>['color'];
  title: ReactNode;
  subtitle?: ReactNode;
}) {
  const space = size === 'small' ? 2 : 6;

  const iconSize = size === 'small' ? 16 : 24;

  const Icon = IconComponent ? (
    <IconComponent
      size={iconSize}
      color={iconColor ?? ((theme) => theme.palette.secondary.dark)}
    />
  ) : null;

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      gap={size === 'small' ? 1 : 2}
      {...props}
      sx={mixinSx(
        {
          border:
            variant === 'contained'
              ? (theme) => `1px solid ${theme.palette.divider}`
              : 'none',
          textAlign: 'center',
          padding: space,
          borderRadius: '10px',
        },
        props.sx
      )}
    >
      {Icon && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 1,
            backgroundColor: (theme) => theme.palette.white,
            border: (theme) => `0.5px solid ${theme.palette.grey30}`,
            borderRadius: '8px',
          }}
        >
          {Icon}
        </Box>
      )}
      {subtitle ? (
        <Stack>
          <Typography variant="h4" sx={{ textWrap: 'balance' }}>
            {title}
          </Typography>
          <Typography variant="body2" sx={{ textWrap: 'balance' }}>
            {subtitle}
          </Typography>
        </Stack>
      ) : (
        <Typography variant="h4" sx={{ textWrap: 'balance' }}>
          {title}
        </Typography>
      )}
      {props.children}
    </Stack>
  );
}

export default BlankSlate;
