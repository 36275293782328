import {
  CustomErrorInvariant,
  makeCustomErrorInvariant,
  makeRethrower,
  WatershedError,
  WatershedErrorOptions,
} from './WatershedError';

export class UnauthenticatedError extends WatershedError {
  status = 401;
  constructor(message?: string, options?: WatershedErrorOptions) {
    super('UNAUTHENTICATED', message, options);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UnauthenticatedError);
    }
    Object.setPrototypeOf(this, UnauthenticatedError.prototype);
  }

  static invariant: CustomErrorInvariant =
    makeCustomErrorInvariant(UnauthenticatedError);
  static wrapAndRethrow = makeRethrower(UnauthenticatedError);
}
