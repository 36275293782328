import {
  CustomErrorInvariant,
  makeCustomErrorInvariant,
  makeRethrower,
  WatershedError,
  WatershedErrorOptions,
} from './WatershedError';

export class NotFoundError extends WatershedError {
  status = 404;
  constructor(message?: string, options?: WatershedErrorOptions) {
    super('NOT_FOUND', message, options);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NotFoundError);
    }
    Object.setPrototypeOf(this, NotFoundError.prototype);
  }

  static invariant: CustomErrorInvariant =
    makeCustomErrorInvariant(NotFoundError);
  static wrapAndRethrow = makeRethrower(NotFoundError);
}
