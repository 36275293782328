import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const ReductionsIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M16 7a1 1 0 1 1 2 0v10a1 1 0 0 1-1 1H7a1 1 0 1 1 0-2h7.586L6.293 7.707a1 1 0 1 1 1.414-1.414L16 14.586V7Z" />
    <path
      fillRule="evenodd"
      d="M4 1a3 3 0 0 0-3 3v16a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3V4a3 3 0 0 0-3-3H4ZM3 4a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4Z"
      clipRule="evenodd"
    />
  </Icon>
));
export default ReductionsIcon;
