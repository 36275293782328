import {
  GQFeatureFlagDetailsFragment,
  GQOrganization,
} from './generated/graphql';
import assertNever from '@watershed/shared-util/assertNever';
import getPathWithQuery from './utils/getPathWithQuery';
import {
  PreviewDeployName,
  adminUrl,
  dashboardUrl,
  temporalWebUrl,
} from './utils/helpers';
import { OverloadParameters } from '@watershed/shared-util/overloadTypes';
import { enableAllPlugins } from 'immer';
import must from './utils/must';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import { ParsedUrlQueryInput } from 'querystring';
import {
  GLOBAL_TAG_NAME_QUERY_PARAM,
  GLOBAL_TAG_VERSION_QUERY_PARAM,
} from './footprintTagging/constants';

// Allow us to use all the immer features
// https://immerjs.github.io/immer/installation/#pick-your-immer-version
enableAllPlugins();

export const ORGS_ROUTE = '/';
export const BACKGROUND_JOBS_ROUTE = '/jobs';
export type RouteableTypename =
  | 'CanonicalClimateProgramProject'
  | 'CanonicalDataset'
  | 'Company'
  | 'Dataset'
  | 'EngagementTaskConfig'
  | 'MarketplaceProject'
  | 'MarketplaceSupplier'
  | 'MarketplaceDeveloper'
  | 'MarketplaceProjectArchetype'
  | 'MarketplaceProjectOffering'
  | 'MarketplacePurchase'
  | 'MarketplacePurchaseLineItem'
  | 'MarketplaceDocument'
  | 'MeasurementVerificationItem'
  | 'Organization'
  | 'OrganizationFootprints'
  | 'OrganizationDataReview'
  | 'OrganizationCreateFootprintTag'
  | 'OrganizationExtractors'
  | 'OrganizationUsers'
  | 'OrganizationDatasets'
  | 'OrganizationFlags'
  | 'OrganizationFiles'
  | 'OrganizationFootprintQuestion'
  | 'OrganizationEmails'
  | 'OrganizationPlans'
  | 'OrganizationPlanCategories'
  | 'OrganizationCompanyChangeRequests'
  | 'OrganizationEmployeeReport'
  | 'OrganizationMappings'
  | 'OrganizationReports'
  | 'OrganizationDataIssues'
  | 'OrganizationProjectDataIssues'
  | 'OrganizationDataIssue'
  | 'OrganizationMeasurementContext'
  | 'OrganizationEngagementTasks'
  | 'OrganizationMarketplace'
  | 'SupplyChainCharts'
  | 'UserUploadTask'
  | 'OrganizationDataset'
  | 'DataIssue'
  | 'IntegrationConnection'
  | 'ReportConfig';

export const SUB_SUB_ID_TYPENAMES = ['DataIssue'] as const;
export type SubSubIdTypenames = (typeof SUB_SUB_ID_TYPENAMES)[number];
export const SUB_ID_TYPENAMES = [
  'CanonicalClimateProgramProjectDataRequirement',
  'Dataset',
  'MarketplacePurchaseLineItem',
  'OrganizationFootprintQuestion',
  'OrganizationPlanCategories',
  'OrganizationProjectDataIssues',
  'OrganizationDataIssue',
  'UserUploadTask',
  'OrganizationDataset',
  'MeasurementVerificationItem',
] as const;
export type SubIdTypenames = (typeof SUB_ID_TYPENAMES)[number];

type IdOnlyTypeNames = Exclude<
  RouteableTypename,
  SubSubIdTypenames | SubIdTypenames
>;

export interface UrlForObject {
  (
    typename: SubIdTypenames,
    id: string,
    subId: string,
    subSubId?: string | undefined
  ): string;
  (
    typename: IdOnlyTypeNames,
    id: string,
    subId?: string | undefined,
    subSubId?: string | undefined
  ): string;
  // fallback to requiring all ids for `routeForObject` below and for subSubId types
  (
    typename: RouteableTypename,
    id: string,
    subId: string,
    subSubId: string
  ): string;
}

export const urlForObject: UrlForObject = (
  ...[typename, id, subId, subSubId]: OverloadParameters<UrlForObject>
): string => {
  switch (typename) {
    case 'CanonicalClimateProgramProject':
      return `/canonical-projects/${id}`;
    case 'CanonicalClimateProgramProjectDataRequirement':
      return `/canonical-projects/${id}?editedRequirementId=${subId}`;
    case 'CanonicalDataset':
      return `/canonical-datasets/?canonicalDatasetId=${id}`;
    case 'Company':
      return `/companies/${id}`;
    case 'Dataset':
      return `/org/${id}/dataset/${subId}`;
    case 'EngagementTaskConfig':
      return `/engagement-tasks/${id}`;
    case 'MarketplaceProject':
      return `/marketplace/projects/${id}`;
    case 'MarketplaceSupplier':
      return `/marketplace/suppliers/${id}`;
    case 'MarketplaceDeveloper':
      return `/marketplace/developers/${id}`;
    case 'MarketplaceProjectArchetype':
      return `/marketplace/archetypes/${id}`;
    case 'MarketplaceProjectOffering':
      return `/marketplace/offerings/${id}`;
    case 'MarketplacePurchase':
      return `/marketplace/purchases/${id}`;
    case 'MarketplaceDocument':
      return `/marketplace/documents/${id}`;
    case 'MarketplacePurchaseLineItem':
      return `/marketplace/purchases/${id}/lineItems/${subId}`;
    case 'Organization':
      return `/org/${id}`;
    case 'OrganizationCompanyChangeRequests':
      return `/org/${id}/company-change-requests`;
    case 'OrganizationFootprints':
      return `/org/${id}/footprints`;
    case 'OrganizationDataReview':
      return `/org/${id}/data-review`;
    case 'OrganizationCreateFootprintTag':
      return `/org/${id}/footprint-tags-editor/new`;
    case 'OrganizationFootprintQuestion':
      return `/org/${id}?orgInboxDialog=question:${subId}`;
    case 'OrganizationExtractors':
      return `/org/${id}/extractors`;
    case 'OrganizationUsers':
      return `/org/${id}/users`;
    case 'OrganizationDatasets':
      return `/org/${id}/datasets`;
    case 'OrganizationFiles':
      return `/org/${id}/files`;
    case 'OrganizationFlags':
      return `/flags?orgId=${id}`;
    case 'OrganizationPlans':
      return `/org/${id}/plans`;
    case 'OrganizationEmails':
      return `/email?orgId=${id}`;
    case 'OrganizationPlanCategories':
      return `/org/${id}/plans/${subId}`;
    case 'OrganizationDataIssues':
      return `/org/${id}/issues`;
    case 'OrganizationProjectDataIssues':
      return `/org/${id}/issues?projectId=${subId}`;
    case 'OrganizationDataIssue':
      return `/org/${id}/issues?issueId=${subId}`;
    case 'OrganizationEmployeeReport':
      return `/org/${id}/employee-report`;
    case 'OrganizationMappings':
      return `/org/${id}/mappings`;
    case 'OrganizationReports':
      return `/org/${id}/reports`;
    case 'OrganizationMeasurementContext': {
      const base = `/org/${id}/measurement-context`;
      // subId is actually a stringified query string here
      if (subId) {
        return `${base}?${subId}`;
      }
      return base;
    }
    case 'OrganizationEngagementTasks':
      return `/org/${id}/supplyChain`;

    case 'OrganizationMarketplace':
      return `/marketplace?orgId=${id}`;
    case 'SupplyChainCharts':
      return `org/${id}/supplyChain/charts`;
    case 'UserUploadTask':
      return `/org/${id}/user-upload-task/${subId}`;
    case 'OrganizationDataset':
      return `/org/${id}/dataset/${subId}`;
    case 'IntegrationConnection':
      return `/integrations/${id}`;
    case 'MeasurementVerificationItem':
      return `/org/${id}/verification-item/${subId}`;
    case 'DataIssue':
      return `/org/${id}/project/${subId}/issues/${subSubId}`;
    case 'ReportConfig':
      return `/reports/configs/${id}`;
    default:
      assertNever(typename);
  }
};

export enum FinanceOrgAdminTab {
  MeasurementProgress = 'measurementProgress',
  PortcoLogin = 'portcoLogin',
  Funds = 'funds',
  Disclosures = 'disclosures',
  EngagementTasks = 'engagementTasks',
  AssetDeduplication = 'assetDeduplication',
  EnqueueEstimation = 'enqueueEstimation',
}

export function routeForObject(typename: RouteableTypename): string {
  return urlForObject(typename, ':id', ':subId', ':subSubId');
}

export function urlForActivityDataTable(
  orgId: string,
  adtId: string,
  options: {
    uutId?: string;
    reproductionReviewId?: string;
  } = {}
): string {
  let path = `/org/${orgId}/adts/${adtId}`;
  if (options.reproductionReviewId) {
    path = `${path}/reproduce/${options.reproductionReviewId}`;
  }

  return getPathWithQuery(path, {
    uutId: options.uutId,
  });
}

export function urlForMappings(
  orgId: string,
  options: {
    reproId?: string;
  } = {}
): string {
  const path = `/org/${orgId}/mappings`;
  if (options.reproId) {
    return getPathWithQuery(path, {
      reproId: options.reproId,
    });
  }
  return path;
}

export function urlForQueryPlayground(
  params: {
    orgId?: string;
    sql?: string;
    /** Shortlinked query id */
    queryId?: string;
  } = {}
): string {
  return getPathWithQuery('/query-playground', params);
}

export function routeForQueryPlayground(): string {
  return urlForQueryPlayground();
}

export function routeForIntegrationsIndex(): string {
  return '/integrations';
}

export function routeForMarketplaceIndex({
  orgId,
}: { orgId?: string } = {}): string {
  return getPathWithQuery('/marketplace', { orgId });
}

export function routeForMarketplaceSuppliersList(): string {
  return '/marketplace/suppliers';
}

export function routeForMarketplaceProjectArchetypesList(): string {
  return '/marketplace/archetypes';
}

export function routeForMarketplaceProjectsList({
  archetypeId,
}: {
  archetypeId?: string;
} = {}): string {
  return getPathWithQuery('/marketplace/projects', { archetypeId });
}

export function routeForMarketplaceDevelopersList(): string {
  return '/marketplace/developers';
}

export function routeForMarketplaceProjectOfferingsList({
  archetypeId,
  projectId,
}: {
  archetypeId?: string;
  projectId?: string;
} = {}): string {
  return getPathWithQuery('/marketplace/offerings', { projectId, archetypeId });
}

export function routeForMarketplaceDocumentsList(): string {
  return '/marketplace/documents';
}

export function routeForMarketplacePriceEstimatesList(): string {
  return '/marketplace/price-estimates';
}

export function routeForReferenceData(): string {
  return '/reference-data';
}

export function routeForReferenceDataCitations(): string {
  return '/reference-data/citations';
}

export function routeForOrgSpecificMethodologyData(
  orgId: string,
  tab?: string
): string {
  return getPathWithQuery(`/org/${orgId}/methodology-data`, { tab });
}

export function routeForReferenceDataCitation(
  referenceDataCitationId: string
): string {
  return `/reference-data/citations/${referenceDataCitationId}`;
}

export function routeForReferenceDataSource(
  referenceDataSourceId: string,
  orgId?: string
): string {
  return getPathWithQuery(`/reference-data/source/${referenceDataSourceId}`, {
    orgId,
  });
}

export function routeForReferenceDataSourceFind(query: string): string {
  return `/reference-data/source/find/${query}`;
}

export function routeForReferenceDataVersionSchemaEditor(
  referenceDataSourceId: string,
  referenceDataVersionId: string
): string {
  return `/reference-data/source/${referenceDataSourceId}/version/${referenceDataVersionId}/schema-editor`;
}

export function routeForCreatingRefDataRevision({
  referenceDataSourceId,
  referenceDataVersionId,
  orgId,
}: {
  referenceDataSourceId: string;
  referenceDataVersionId: string;
  orgId?: string;
}): string {
  return getPathWithQuery(`/reference-data/create-revision`, {
    referenceDataSourceId,
    referenceDataVersionId,
    orgId,
  });
}

export function routeForCreatingRefDataOverlay({
  referenceDataSourceId,
  referenceDataVersionId,
  orgId,
  oneSchemaTemplateKey,
  citationSlug,
}: {
  referenceDataSourceId: string;
  referenceDataVersionId: string;
  orgId: string;
  oneSchemaTemplateKey: string;
  citationSlug: string | null;
}): string {
  return getPathWithQuery(`/reference-data/create-overlay`, {
    referenceDataSourceId,
    referenceDataVersionId,
    orgId,
    oneSchemaTemplateKey,
    citationSlug,
  });
}

export function routeForPreviewingReferenceDataOverlay({
  referenceDataOverlayId,
}: {
  referenceDataOverlayId: string;
}): string {
  return getPathWithQuery('/reference-data/preview-new-overlay', {
    referenceDataOverlayId,
  });
}

export function routeForCompositeDataVersion(
  compositeDataSourceId: string,
  compositeDataVersionId: string,
  orgId?: string | null
): string {
  return getPathWithQuery(
    `/reference-data/composite-source/${compositeDataSourceId}/version/${compositeDataVersionId}`,
    { orgId }
  );
}

export function routeForReferenceDataCompare(
  sourceRevisionId: string,
  targetRevisionId: string
): string {
  return `/reference-data/compare/${sourceRevisionId}/${targetRevisionId}`;
}

export function routeForLegacyBusinessActivityTypesForTypeNameAndVersion(
  versionName: string = ':versionName',
  typeName: string = ':typeName'
): string {
  return `/legacy-business-activity-types/${versionName}/${typeName}`;
}

export function routeForLegacyBusinessActivityTypesForVersion(
  versionName: string = ':versionId'
): string {
  return `/legacy-business-activity-types/${versionName}`;
}

export function routeForBusinessActivityTypeId(
  businessActivityTypeId: string
): string {
  return `/business-activity-types/${businessActivityTypeId}`;
}

export function routeForBusinessActivityTypes(): string {
  return `/business-activity-types`;
}

export function routeForLegacyBusinessActivityTypes(): string {
  return `/legacy-business-activity-types`;
}

export function routeForReportAnswerVerifier(): string {
  return `/reports/report-answer-verifier`;
}

export function routeForReportHealthChecks(): string {
  return `/reports/healthchecks`;
}

export function routeForReportConfigs(): string {
  return `/reports/configs`;
}

export function routeForReportConfigObject(reportObjectId: string): string {
  return `/reports/configs/${reportObjectId}`;
}

export function routeForReportQuestionAnswers(
  reportQuestionId?: string
): string {
  return `/reports/answers${
    reportQuestionId ? `?reportQuestionId=${reportQuestionId}` : ''
  }`;
}

export function routeForAssumptionChangeset(
  changesetId: string = ':changesetId'
): string {
  return `/assumptions/changeset/${changesetId}`;
}

export function routeForEngagementTasks(): string {
  return '/engagement-tasks';
}

export function routeForCloudFileStorage(): string {
  return '/cloud-file-storage';
}

export function routeForEmissionsModels(): string {
  return '/emissions-models';
}

export function routeForCalculationTags(): string {
  return '/calculation-tags';
}

export function routeForDescriptions(): string {
  return '/descriptions';
}

export function routeForEmissionsModel(emissionsModelId: string): string {
  return `/emissions-models/${emissionsModelId}`;
}

export function routeForEmissionsModelEvaluation(id: string): string {
  return `/emissions-model-evaluations/${id}`;
}

export function routeForDuckHunt(): string {
  return '/duck-hunt';
}

export function routeForParquetViewer(objectId?: string | undefined): string {
  return `/parquetviewer` + (objectId ? `?objectId=${objectId}` : '');
}

export function routeForOrganization(orgId: string): string {
  return `/org/${orgId}`;
}

export function routeForOrganizationUsers(orgId: string): string {
  return `/org/${orgId}/users`;
}

export function routeForOrganizationEngagementTasks(orgId: string): string {
  return `/org/${orgId}/supplyChain/engagement-tasks`;
}

export function routeForSupplyChainCharts(orgId: string): string {
  return `/org/${orgId}/supplyChain/charts`;
}

export function routeForSupplyChainAutomapping(orgId: string): string {
  return `/org/${orgId}/supplyChain/automapping`;
}

export function routeForMeasurementTests(): string {
  return `/measurement-tests`;
}

export function routeForMeasurementTestSuite(
  testSuiteId: string,
  { showChangelog }: { showChangelog?: boolean } = {}
): string {
  return `/measurement-tests/${testSuiteId}${
    showChangelog ? '?changeLog=true' : ''
  }`;
}

// Note: keep this up to date if the workflow name changes
export function measurementTestSuiteTemporalWorkflowUrl(
  testSuiteId: string
): string {
  return temporalWorkflowUrl(
    `executeMeasurementTestSuiteWorkflow-${testSuiteId}`
  );
}

export function routeForMethodologyExplorer({
  nodeId,
}: { nodeId?: string } = {}): string {
  // Keep these query params in sync, since they live in admin not a universal spot
  return getPathWithQuery('/methodology-explorer', { centeredNodes: nodeId });
}

export function adminLoginAsUrl({
  orgId,
  userId,
  redirect,
  previewDeployName,
}: {
  orgId: string;
  userId?: string;
  redirect?: string;
  previewDeployName?: PreviewDeployName;
}): string {
  const params: Record<string, string> = {};
  if (userId) {
    params.user_id = userId;
  }
  if (redirect) {
    params.redirect = redirect;
  }
  if (previewDeployName) {
    params.previewDeployName = previewDeployName;
  }
  const adminPath = getPathWithQuery(`/org/${orgId}/login_as`, params);
  return adminUrl(adminPath);
}

export function adminLoginAsMyselfUrl(
  orgId: string,
  options?: {
    previewDeployName?: PreviewDeployName;
  }
): string {
  const params: Record<string, string> = {};
  if (options?.previewDeployName) {
    params.previewDeployName = options.previewDeployName;
  }
  const adminPath = getPathWithQuery(`/org/${orgId}/login_as_myself`, params);
  return adminUrl(adminPath);
}

export function loginAsApiEndpointUrl({
  orgId,
  userId,
  redirect,
  token,
  previewDeployName,
}: {
  orgId: string;
  userId?: string;
  redirect?: string;
  token?: string;
  previewDeployName?: PreviewDeployName;
}): string {
  const params: Record<string, string> = { org_id: orgId };
  if (userId) {
    params.user_id = userId;
  }
  if (redirect) {
    params.redirect = redirect;
  }
  if (token) {
    params.token = token;
  }
  const dashboardPath = getPathWithQuery('/auth/login_as', params);
  return dashboardUrl(dashboardPath, { previewDeployName });
}

export function routeForObjectViewer(objectId?: string): string {
  return getPathWithQuery('/objectviewer', { objectId });
}

export function routeForFootprintDebugViewer(fpsId?: string): string {
  return getPathWithQuery('/footprint-debug-viewer', { fpsId });
}

export function routeForWatershedEmployees(): string {
  return '/employees';
}

export function temporalWorkflowUrl(
  workflowId: string,
  runId?: string
): string {
  const namespace = must(
    process.env.NEXT_PUBLIC_TEMPORAL_NAMESPACE_ID ??
      process.env.TEMPORAL_NAMESPACE_ID
  );

  return temporalWebUrl(
    `/namespaces/${namespace}/workflows/${workflowId}${
      runId ? `/${runId}` : ''
    }`
  );
}

export function backgroundJobUrl(jobId: string): string {
  return jobId.startsWith('aj_')
    ? adminUrl(routeForObjectViewer(jobId))
    : temporalWorkflowUrl(jobId);
}

export function routeForCompanies(includeDemo: boolean = false): string {
  return `/companies${includeDemo ? '?includeDemo=true' : ''}`;
}

export function routeForCompaniesCreate(): string {
  return `/companies/create`;
}

export function routeForCompaniesUpdate(companyId: string): string {
  return `/companies/${companyId}/edit`;
}

export function routeForCompaniesIngest(): string {
  return `/companies/ingest`;
}

export function routeForCompaniesIngestionDiffErrors(): string {
  return `/companies/ingestionDiffErrors`;
}

export function routeForCompaniesSources(): string {
  return '/companies/sources';
}

export function routeForCompany(companyId: string): string {
  return `/companies/${companyId}`;
}

export function routeForFeatureFlags(): string {
  return '/flags';
}

export function routeForFeatureFlagsForOrg(
  org: Pick<GQOrganization, 'id'>
): string {
  return `/flags?orgId=${org.id}`;
}

export function routeForFeatureFlag(
  flag: Pick<GQFeatureFlagDetailsFragment, 'name'>
): string {
  return `/flags?flag=${flag.name}`;
}

export function routeForFinance(orgId: string): string {
  return `${urlForObject('Organization', orgId)}/finance`;
}

export function routeForFinanceAdmin(
  orgId: string,
  query: ParsedUrlQueryInput,
  tab?: FinanceOrgAdminTab
): {
  pathname: string;
  query?: ParsedUrlQueryInput;
} {
  return {
    pathname: `${urlForObject('Organization', orgId)}/finance/${
      tab ?? FinanceOrgAdminTab.MeasurementProgress
    }`,
    query,
  };
}

export function routeForCanonicalSchemas(): string {
  return '/canonical-schemas';
}

export function routeForCanonicalSchema(id: string): string {
  return `/canonical-schemas/${id}`;
}

export function routeForCustomerTargetSchemas(): string {
  return '/customer-target-schemas';
}

export function routeForCustomerTargetSchema({
  schemaId,
}: {
  schemaId: string;
}): string {
  return ['/customer-target-schemas', schemaId].filter(isNotNullish).join('/');
}

export function routeForLicensedCdpAnswers(): string {
  return '/reports/licensed-cdp-answers';
}

export function routeForReportQuestionMapping(): string {
  return '/reports/question-mapping';
}

export function routeForCdpIdMapping(): string {
  return '/reports/cdp-identifier-mapping';
}

export function routeForGlobalFootprintTagEditor({
  tagName,
  versionId,
}: {
  tagName?: string;
  versionId?: string;
} = {}): string {
  const params = new URLSearchParams();

  if (tagName) {
    params.append(GLOBAL_TAG_NAME_QUERY_PARAM, tagName);
  }
  if (versionId) {
    params.append(GLOBAL_TAG_VERSION_QUERY_PARAM, versionId);
  }
  return getPathWithQuery('/default-tags-editor', params);
}

export function routeForUnitConverter(): string {
  return '/unit-converter';
}

export function routeForFootprintTaggingEditor(
  orgId: string,
  tagId?: string
): string {
  const baseUrl = `${urlForObject(
    'Organization',
    orgId
  )}/footprint-tags-editor`;
  return tagId ? `${baseUrl}/${tagId}` : `${baseUrl}/new`;
}

export function routeForTcfdRisks(): string {
  return '/tcfd-utilities/risks';
}

export function routeForTcfdOpportunities(): string {
  return '/tcfd-utilities/opportunities';
}

export function routeForGmailTools(): string {
  return '/gmail';
}

export function routeForCanonicalDatasets(): string {
  return '/canonical-datasets';
}

export function routeForCanonicalDataset(canonicalDatasetId: string): string {
  return getPathWithQuery('/canonical-datasets', {
    canonicalDatasetId,
  });
}

export function routeForSentEmails(): string {
  return '/email';
}

export function routeForCanonicalProjects(): string {
  return '/canonical-projects';
}

export function routeForReportConfigDependencySchema(): string {
  return 'https://github.com/watershed-climate/ghg/blob/main/workspaces/shared-universal/report/types/reportQuestionDependency.ts#L74';
}

export function routeForOnboardingStart(): string {
  return dashboardUrl('/onboarding/company-metadata');
}

export function routeForFootprintDetailsInProduct(
  footprintVersionId: string
): string {
  return dashboardUrl(
    `/footprints/details?footprintVersion=${footprintVersionId}`
  );
}

export function routeForSupplierTaskEditInProduct(taskId: string): string {
  return dashboardUrl(`/footprints/suppliers/config/tasks/${taskId}/edit`);
}

export function routeForSupplierTasksInProduct(): string {
  return dashboardUrl('/footprints/suppliers/config/tasks');
}

export function routeForFootprintListInProduct(): string {
  return dashboardUrl('/footprints');
}

export function routeForEmissionsModelReleases(): string {
  return '/calculation-method-versions';
}

export function routeForEmissionsModelRelease(releaseId: string): string {
  return routeForEmissionsModelReleases() + `/${releaseId}`;
}

export function routeForStorybookInProduction(): string {
  return `https://go.watershed.com/sb`;
}

export function routeForIconsInStorybook(): string {
  return `http://go.watershed.com/icons`;
}

export function routeForSystemModelArchitecture(): string {
  return `http://go.watershed.com/architecture`;
}

export function routeForImpactAssessment(): string {
  return '/impact-assessment-tooling';
}

export function routeForTSchemaPlatform(): string {
  return '/on-the-t';
}

export function routeForTSchemaVersionPlatform(
  schemaId: string | null
): string {
  return schemaId
    ? `/on-the-t/tschema-version?schemaId=${schemaId}`
    : '/on-the-t/tschema-version';
}

export function routeForTSchemaMigrators(schemaId: string | null): string {
  return schemaId
    ? `/on-the-t/migrator?schemaId=${schemaId}`
    : '/on-the-t/migrator';
}

export function routeForTSchemaVersionViewer(schemaVersionId: string): string {
  return `/on-the-t/tschema-version/${schemaVersionId}`;
}

export function routeForMigratorViewer(migratorId: string): string {
  return `/on-the-t/migrator/${migratorId}`;
}

export function routeForMigrationsPlatform(): string {
  return '/on-the-t/migrations';
}

export function routeForDataRegistryBatchMigratorRun(
  batchMigratorRunId: string
): string {
  return `/on-the-t/migrations/${batchMigratorRunId}`;
}

export function routeForLifecycleAssessmentsForOrgId(orgId: string): string {
  return `/org/${orgId}/production-graph/lifecycle-assessment`;
}

export function routeForMaterialVariantsForOrgId(orgId: string): string {
  return `/org/${orgId}/production-graph/material-variant`;
}
