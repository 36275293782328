// eslint-disable-next-line no-restricted-imports
export * from '@mui/x-data-grid-premium';
export type { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
export type { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
export type { GridProSlotProps } from '@mui/x-data-grid-pro/models/gridProSlotProps';
// eslint-disable-next-line no-restricted-imports
export { GridFilterPanel } from '@mui/x-data-grid-premium';
// eslint-disable-next-line no-restricted-imports
import {
  DataGridPremium,
  GridApi,
  GridValidRowModel,
  GridValueGetterParams,
  GridToolbarQuickFilter as MuiGridToolbarQuickFilter,
  GridToolbarQuickFilterProps,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridTreeNode,
  GridGroupNode,
  GridKeyValue,
  gridClasses,
} from '@mui/x-data-grid-premium';
import { Plural, useLingui } from '@lingui/react/macro';
import { FormikProvider } from 'formik';
import { mixinSx } from '@watershed/style/styleUtils';
import {
  InputAdornment,
  Stack,
  StackProps,
  SxProps,
  Theme,
  Typography,
  Box,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import InfoIcon from '@watershed/icons/components/Info';
import clsx from 'clsx';
import sortBy from 'lodash/sortBy';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import {
  WATERSHED_BIG_ROCK_DATA_GRID_ROW_HEIGHT,
  bigRockSx,
} from './bigRockSx';
import { bigRockIconSlots } from './bigRockIconSlots';
import {
  bigRockPaginationSlots,
  bigRockPaginationSx,
} from './bigRockPaginationCustomization';
import { bigRockMenuSlotProps, bigRockMenuSlots } from './bigRockMenuSlots';
import usePaletteUtils from '../../hooks/usePaletteUtils';
import SearchIcon from '@watershed/icons/components/Search';
import {
  DataGridProps,
  GridContext,
  CUSTOM_SLOT_TYPES,
  useGridContext,
  type LocalizedGridColDef,
} from './DataGridTypes';
import omit from 'lodash/omit';
import { bigRockToolbarSlots } from './bigRockToolbarCustomization';
import { TextFieldSearch } from '../Form/TextField';
import { loadingSlots } from './loadingSlots';
import React from 'react';
import {
  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @typescript-eslint/no-restricted-imports
  pluralize,
} from '@watershed/shared-universal/utils/helpers';

export * from './DataGridTypes';

export const WATERSHED_DATA_GRID_ROW_HEIGHT = 38;
const ERROR_CELL_CLASS_NAME = 'cell-error';
export type GridColumns<
  R extends GridValidRowModel = any,
  V = any,
  F = V,
> = Array<GridColDef<R, V, F>>;

export const GridToolbar = (props: { sx?: SxProps<Theme> }) => (
  // TODO: get SxProps<Theme>'s to match
  <GridToolbarContainer sx={mixinSx({ padding: 0 }, props.sx) as any}>
    <Stack direction="row" gap={1}>
      <GridToolbarColumnsButton size="medium" />
      {/* This should use slotProps but it doesn't exist on the type! Keeping it as a componentsProps for now */}
      <GridToolbarFilterButton
        componentsProps={{ button: { size: 'medium' } }}
      />
      <GridToolbarDensitySelector size="medium" />
      <GridToolbarExport size="medium" />
    </Stack>
  </GridToolbarContainer>
);

export function GridToolbarQuickFilter(
  props: GridToolbarQuickFilterProps & { fsUnmask?: boolean }
) {
  const paletteUtils = usePaletteUtils();
  return (
    <MuiGridToolbarQuickFilter
      {...props}
      inputProps={{
        ...(props.inputProps || {}),
        className: clsx(
          props.inputProps?.className,
          props.fsUnmask && 'fs-unmask'
        ),
        'data-testid': 'GridToolbarQuickFilter',
      }}
      // Make it look like `<TextFieldSearch>`
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color={(theme) => theme.palette.info.main} size={16} />
          </InputAdornment>
        ),
        endAdornment: null,
      }}
      sx={mixinSx(
        {
          '&.MuiTextField-root': {
            paddingBottom: 0,
          },
          '& .MuiInput-input': {
            ...paletteUtils.textFieldStyles,
            paddingLeft: '36px',
            height: 24,
          },
          '& .MuiFormControl-root': { padding: 0 },
          // Search icon
          '& .MuiInputAdornment-root': {
            position: 'absolute',
            left: 14,
          },
          // Clear button
          '& .MuiIconButton-root': {
            marginLeft: '4px',
          },
        },
        props.sx
      )}
    />
  );
}

export function DataGrid<R extends GridValidRowModel, FormikFilters = {}>({
  id,
  search,
  setSearch,
  filtersFormik,
  createRecord,
  aggregation = false,
  bigRocked = true,
  sx,
  slots: initialSlots,
  slotProps: initialSlotProps,
  ...restProps
}: DataGridProps<R, FormikFilters>) {
  // With BigRocks on, we default to _showing_ the row count as it's more helpful than not.
  const disableRowCountDisplay =
    restProps.disableRowCountDisplay ?? (bigRocked ? false : true);

  // Only pass if present; if `slots` is passed, then it clobbers `components`,
  // which will cause e.g. toolbars on grids to not display. Dangerous.
  const slots = {
    ...(bigRocked ? bigRockIconSlots : undefined),
    ...(bigRocked ? bigRockPaginationSlots : undefined),
    ...(bigRocked ? bigRockMenuSlots : undefined),
    ...omit(initialSlots, CUSTOM_SLOT_TYPES),
    ...(bigRocked
      ? bigRockToolbarSlots /* renders initialSlots.toolbar internally */
      : undefined),
    ...loadingSlots,
  };

  const slotProps = {
    ...(bigRocked ? bigRockMenuSlotProps : undefined),
    ...initialSlotProps,
  };

  const content = (
    <GridContext.Provider
      value={{
        search,
        setSearch,
        createRecord,
        slots: initialSlots,
        slotProps: initialSlotProps,
      }}
    >
      <DataGridPremium<R>
        aria-label={`Data grid: ${id}`}
        disableAggregation
        rowHeight={
          bigRocked
            ? WATERSHED_BIG_ROCK_DATA_GRID_ROW_HEIGHT
            : WATERSHED_DATA_GRID_ROW_HEIGHT
        }
        slots={Object.keys(slots).length > 0 ? slots : undefined}
        slotProps={omit(slotProps, CUSTOM_SLOT_TYPES)}
        {...restProps}
        sx={mixinSx(
          {
            // TODO: Move this to theme
            '& .MuiDataGrid-columnHeaderTitle': {
              lineHeight: '18px', // Using watershed's fonts, "g" gets cut off slightly at the default "16px"
            },
            [`& .${ERROR_CELL_CLASS_NAME}`]: {
              backgroundColor: (theme) => theme.palette.sun10,
              '&:focus': {
                borderRadius: '2px',
                outline: (theme) => `1.5px solid ${theme.palette.sun50}`,
                outlineOffset: '-1.5px',
                backgroundColor: (theme) => `${theme.palette.sun10} !important`, //
              },
            },
          },
          disableRowCountDisplay && {
            '& .MuiTablePagination-displayedRows': {
              display: 'none', // 👈 to hide huge pagination number
            },
          },
          bigRocked && bigRockPaginationSx,
          bigRocked && bigRockSx,
          sx
        )}
      />
    </GridContext.Provider>
  );
  if (filtersFormik) {
    return <FormikProvider value={filtersFormik}>{content}</FormikProvider>;
  }
  return content;
}

export interface DataGridColumnHeaderProps
  extends Omit<StackProps, 'children' | 'title'> {
  tooltip?: TooltipProps['title'];
  tooltipProps?: Omit<TooltipProps, 'children' | 'title'>;
  showTooltipIcon?: boolean;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  adornment?: React.ReactNode;
}

DataGrid.ColumnHeader = function DataGridColumnHeader({
  tooltip,
  tooltipProps,
  showTooltipIcon = false,
  title,
  subtitle,
  adornment,
  sx,
  ...props
}: DataGridColumnHeaderProps) {
  const content = (
    <Stack
      direction="row"
      className="DataGridColumnHeader"
      {...props}
      sx={mixinSx(
        {
          maxWidth: '100%',
          alignItems: 'center',
          gap: 1,
        },
        sx
      )}
    >
      <Stack
        sx={{
          overflow: 'hidden',
          maxWidth: '100%', // enables truncation
          [`.${gridClasses['columnHeader--alignCenter']} &`]: {
            textAlign: 'center',
            alignItems: 'center',
          },
          [`.${gridClasses['columnHeader--alignRight']} &`]: {
            textAlign: 'right',
            alignItems: 'flex-end',
          },
        }}
      >
        <Typography variant="h4" color="inherit" children={title} noWrap />
        {subtitle && (
          <Typography
            variant="body3"
            color="textSecondary"
            children={subtitle}
            noWrap
          />
        )}
      </Stack>
      {adornment}
      {tooltip && showTooltipIcon && (
        <InfoIcon
          size={16}
          color="info.main"
          flexShrink={0}
          // Hide from PNG export
          data-html2canvas-ignore
        />
      )}
    </Stack>
  );

  if (tooltip) {
    return (
      <Tooltip title={tooltip} {...tooltipProps}>
        {content}
      </Tooltip>
    );
  }
  return content;
};

/**
 * Transforms LocalizedGridColDef arrays to GridColDef arrays via lingui.
 */
export function useLocalizedColumns<
  R extends GridValidRowModel,
  V = any,
  F = V,
>(columns: Array<LocalizedGridColDef<R, V, F>>): Array<GridColDef<R, V, F>> {
  const { i18n } = useLingui();
  return columns.map((column) => ({
    ...column,
    headerName: i18n._(column.headerName),
  }));
}

/**
 * Determines if the row is in group by mode
 * @param rowNode - typically found in `params` for column functions
 * @returns boolean
 */
export const isGroupRow = (
  rowNode: GridTreeNode | undefined | null
): rowNode is GridGroupNode => rowNode?.type === 'group';

export const getGroupingField = (
  rowNode: GridTreeNode | undefined | null
): string | undefined =>
  isGroupRow(rowNode) ? (rowNode.groupingField ?? undefined) : undefined;

export const getGroupingKey = (
  rowNode: GridTreeNode | undefined | null
): GridKeyValue | undefined =>
  isGroupRow(rowNode) ? (rowNode.groupingKey ?? undefined) : undefined;

export const isPinnedRow = (
  rowNode: GridTreeNode | undefined | null
): boolean =>
  !!rowNode &&
  (rowNode?.type === 'pinnedRow' ||
    ('isPinned' in rowNode && !!rowNode.isPinned));

/**
 * Group by function - sums up all values
 * @param apiRef - needed to get all row data
 * @returns a sum of all values. Best used with numbers
 */
export const summationValueGetting =
  (apiRef: React.MutableRefObject<GridApi>) =>
  (params: GridValueGetterParams) => {
    if (isGroupRow(params.rowNode)) {
      const rows = apiRef.current
        .getRowGroupChildren({
          groupId: params.rowNode.id,
        })
        .map((rowId) => apiRef.current.getRow(rowId));
      return rows.reduce((acc, ea) => acc + ea[params.field], 0);
    }
    return params.value;
  };

/**
 * Group by function - weighted average of all values
 * @param apiRef - needed to get all row data
 * @param unitField - used to 'weigh' the values by.
 * @returns the average of all values. This only works with numbers.
 */
export const weightedAverageValueGetter =
  (apiRef: React.MutableRefObject<GridApi>, unitField: string) =>
  (params: GridValueGetterParams) => {
    if (isGroupRow(params.rowNode)) {
      const rows = apiRef.current
        .getRowGroupChildren({
          groupId: params.rowNode.id,
        })
        .map((rowId) => apiRef.current.getRow(rowId));

      const totalUnits = rows.reduce((acc, ea) => acc + ea[unitField], 0);

      return rows.length > 0
        ? rows.reduce((acc, ea) => acc + ea[params.field] * ea[unitField], 0) /
            totalUnits
        : 0;
    }
    return params.value;
  };

/**
 * Group by function - counts unique string values
 * @param apiRef - needed to get all row data
 * @param fieldName - used to format the 'summary'. this should be in the singular (e.g.: material)
 * @returns a string formatted like `{count} {fieldName}`. (e.g.: 4 materials)
 */
/** @deprecated this does not work with localization */
export const stringCountingValueGetter =
  (apiRef: React.MutableRefObject<GridApi>, fieldName: string) =>
  (params: GridValueGetterParams) => {
    if (isGroupRow(params.rowNode)) {
      const rows = apiRef.current
        .getRowGroupChildren({
          groupId: params.rowNode.id,
        })
        .map((rowId) => apiRef.current.getRow(rowId));
      // convert to a set so we can get unique items
      const data = new Set(rows.map((row) => row[params.field]));
      return pluralize(data.size, fieldName);
    }
    return params.value;
  };

/**
 * Group by function - shows all unique string values
 * @param apiRef - needed to get all row data
 * @returns a string with all unique values separated by commas
 */
export const uniqueStringsValueGetter =
  (apiRef: React.MutableRefObject<GridApi>) =>
  (params: GridValueGetterParams) => {
    if (isGroupRow(params.rowNode)) {
      const rows = apiRef.current.getRowGroupChildren({
        groupId: params.rowNode.id,
      });
      // convert to a set so we can get unique items
      const data = new Set(
        rows.map((rowId) => {
          const cellParams = apiRef.current.getCellParams(
            rowId,
            params.colDef.field
          );
          return cellParams.formattedValue ?? cellParams.value;
        })
      );
      // eslint-disable-next-line @watershed/no-join-commas
      return sortBy([...data.values()]).join(', ');
    }
    return params.value;
  };

/**
 * Pass as the argument to cellClassName for a column to conditionally display
 * an error state on a cell. See stories for a usage example
 * @param validationFn a function that returns true if the cell should show an error message
 * @returns a function to pass to cellClassName to conditionally display the error state
 */
export const cellClassNameForValidationFn = (
  validationFn: (params: GridCellParams) => boolean
): ((params: GridCellParams) => string) => {
  return (params) => {
    if (validationFn(params)) {
      return ERROR_CELL_CLASS_NAME;
    }
    return '';
  };
};

function mapHideBooleansToVisibilityModel(
  columns: Array<GridColDef & { hide?: boolean }>
): GridColumnVisibilityModel {
  return mapValues(
    keyBy(columns, (c) => c.field),
    (c) => !c.hide
  );
}

export type GridLegacyHideableColumn<C extends GridColDef> = C & {
  hide?: boolean;
};

export function useLegacyHideColumns<C extends GridColDef>(
  initialColumns: Array<GridLegacyHideableColumn<C>>
): Pick<DataGridProps<any, any>, 'initialState'> & {
  columns: Array<C>;
} {
  return {
    columns: initialColumns,
    initialState: {
      columns: {
        columnVisibilityModel: mapHideBooleansToVisibilityModel(initialColumns),
      },
    },
  };
}

export type TableToolbarProps = {
  filteredCount: number;
  totalCount: number;
};

export const withTableToolbar = ({
  showTotalCount,
  itemName,
  searchPlaceholder,
  sideComponents,
}: {
  showTotalCount: boolean;
  itemName: string;
  searchPlaceholder?: LocalizedString;
  sideComponents?: React.ReactNode;
}) =>
  function TableToolbar({ filteredCount, totalCount }: TableToolbarProps) {
    const { search, setSearch } = useGridContext();
    if (totalCount === 0) {
      return null;
    }
    return (
      <Stack
        direction="row"
        gap={3}
        sx={{
          padding: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 2,
        }}
      >
        {showTotalCount && (
          <Typography
            variant="h2"
            sx={{
              width: '30%',
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            {filteredCount !== totalCount && (
              <Plural
                value={totalCount}
                other={`${filteredCount} of ${totalCount} items`}
              />
            )}
          </Typography>
        )}
        <TextFieldSearch
          id="search-items"
          value={search}
          placeholder={searchPlaceholder ?? `Search for ${itemName}`}
          onChange={(event) => setSearch?.(event.target.value)}
          sx={{ flexGrow: 1 }}
        />
        <Box>{sideComponents}</Box>
      </Stack>
    );
  };
