import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const SetSquareIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M7.878 3.677A8.154 8.154 0 0 0 3.21 9.285l.015.003a2.794 2.794 0 0 0-.089.676 2.856 2.856 0 0 0 2.855 2.854h15.1A1.91 1.91 0 0 1 23 14.728v6.59c0 .93-.753 1.682-1.682 1.682h-3.833a1.002 1.002 0 0 1-.06 0h-9.04a1.004 1.004 0 0 1-.06 0H5.5A4.496 4.496 0 0 1 1 18.5v-7.373C1 5.54 5.539 1 11.127 1c5.589 0 10.127 4.539 10.127 10.127h-2a8.136 8.136 0 0 0-4.878-7.45c.097.201.19.41.276.625.723 1.798 1.148 4.212 1.148 6.825h-2c0-2.422-.398-4.572-1.004-6.078-.304-.757-.641-1.302-.964-1.642-.322-.338-.56-.407-.705-.407-.145 0-.383.07-.704.407-.323.34-.66.885-.965 1.642-.606 1.506-1.003 3.656-1.003 6.078h-2c0-2.613.425-5.027 1.148-6.825.086-.215.178-.424.275-.625Zm1.477 12.87V21h2.554v-2.637a1 1 0 1 1 2 0V21h2.545v-4.454a1 1 0 0 1 2 0V21H21v-6.182H5.99A4.832 4.832 0 0 1 3 13.787V18.5C3 19.884 4.116 21 5.5 21h1.855v-4.454a1 1 0 0 1 2 0Z"
      clipRule="evenodd"
    />
  </Icon>
));
export default SetSquareIcon;
