import type { ComponentProps } from 'react';
import { Trans } from '@lingui/react/macro';
import {
  GRID_CHECKBOX_SELECTION_FIELD,
  GridFilterPanel,
  GridPanelFooter,
  useGridApiContext,
} from './DataGrid';
import { getPaletteUtils, mixinSx } from '@watershed/style/styleUtils';
import { bigRockTextFieldSx, sharedPaperSx } from './bigRockMenuSlots';
import Button from '../Button';
import DeleteIcon from '@watershed/icons/components/Delete';
import AddIcon from '@watershed/icons/components/Add';
import { Stack, SxProps, Theme } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import IconButton from '../IconButton';

// DataGrid unfortunately specifies these operators as unformatted, untyped strings
// Let's map them to strings here for future translation
export const FILTER_OPERATOR_DISPLAY_MAP: Record<string, string> = {
  contains: 'contains',
  equals: 'equals',
  startsWith: 'starts with',
  endsWith: 'ends with',
  is: 'is',
  not: 'is not',
  after: 'after',
  onOrAfter: 'on or after',
  before: 'before',
  onOrBefore: 'on or before',
  isEmpty: 'is empty',
  isNotEmpty: 'is not empty',
  isAnyOf: 'is any of',
  '=': '=',
  '!=': '!=',
  '>': '>',
  '>=': '>=',
  '<': '<',
  '<=': '<=',
};

export function getFilterOperatorDisplayString(operator: string) {
  if (operator in FILTER_OPERATOR_DISPLAY_MAP) {
    return FILTER_OPERATOR_DISPLAY_MAP[operator];
  }
  throw new Error(`Unknown DataGrid filter operator: ${operator}`);
}

const gridFilterPanelSx: SxProps<Theme> = {
  minHeight: 0, // Make sure at max height it allows the buttons below to be visible

  // Don't show the labels at all
  '& .MuiDataGrid-filterForm .MuiInputLabel-root': visuallyHidden,

  '& .MuiInput-root': {
    marginTop: 0, // individual inputs had gaps above, can reset this
  },

  '& .MuiFormControl-root': {
    marginRight: 0, // individual inputs had gaps to the right, can reset this
    gap: 0.75, // between top label and input

    '& .MuiInputLabel-root.Mui-focused': {
      color: 'inherit', // otherwise we get an ugly blue
    },
  },

  '& .MuiDataGrid-panelContent': {
    padding: 1.5, // matches buttons
  },

  '& .MuiDataGrid-filterForm': {
    gap: 1, // between columns, operator, value inputs
    paddingY: 0.5, // Adds up to same between columns
    paddingX: 0,
    display: 'flex',
    alignItems: 'flex-end',
  },

  '& .MuiDataGrid-filterFormDeleteIcon': {
    order: 1, // Shove it at the end of the row
    bottom: -1.5, // with flex-end alignment, this visually centers it with the actual inputs
    borderRadius: 0.75,
    boxShadow: (t) =>
      getPaletteUtils(t.palette).boxShadowButton.secondary.default,
    '&:hover': {
      boxShadow: (t) =>
        getPaletteUtils(t.palette).boxShadowButton.secondary.hover,
    },
    '& .MuiIconButton-root': {
      width: 32,
      height: 32,
    },
    '& svg': {
      width: 16,
      height: 16,
      color: (theme) => theme.palette.text.secondary,
    },
  },

  '& .MuiDataGrid-filterForm:first-of-type .MuiDataGrid-filterFormLogicOperatorInput':
    {
      display: 'inline-flex', // always make sure visible unlike default
      '&:before': {
        content: '"Where"', // Add simple text for the first item!
        color: (t) => t.palette.text.secondary,
        display: 'flex',
        visibility: 'visible',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        padding: 0.5,
      },
    },
};

const operatorInputSx: SxProps<Theme> = {
  width: 130, // slightly larger than default 120 to accommodate our operator length with our standard dropdown
};

const columnInputSx: SxProps<Theme> = {
  width: 180, // slightly larger than default 160 to accommodate longer column titles on average
};

const logicOperatorInputSx: SxProps<Theme> = {
  width: 68,
};

/**
 * Shows up when you filter in a header or click filter in the header dropdown menu.
 */
export function FilterPanel({
  children,
  ...props
}: ComponentProps<typeof GridFilterPanel>) {
  const { current: gridApi } = useGridApiContext();
  const {
    filter: { filterModel },
  } = gridApi.state;

  function addNewFilter() {
    const field = gridApi.state.columns.orderedFields.filter(
      (col) => col !== GRID_CHECKBOX_SELECTION_FIELD
    )[0];
    const colDef = gridApi.getColumn(field);
    gridApi.upsertFilterItem({
      // Don't add a filter for the empty column which can't be filtered by; get the first real column
      field,
      // Try to determine default operator, but fallback to 'contains'
      operator: colDef.filterOperators?.at(0)?.value ?? 'contains',
      id: Date.now(), // needs to be unique
    });
  }

  /**
   * Clears all filters except the first one, sets first value to null, and then closes panel if
   * we already had nothing filtered (default behavior)
   */
  function clearFilters() {
    const hadMultipleFilters = filterModel.items.length > 1;
    const firstFilter = filterModel.items[0];
    if (!firstFilter) {
      // Don't do anything if there are already no filters
      return;
    }
    gridApi.setFilterModel(
      { items: [{ ...firstFilter, value: undefined }] },
      'deleteFilterItem'
    );
    if (!hadMultipleFilters) {
      gridApi.hideFilterPanel();
    }
  }

  return (
    <Stack data-testid="filter-panel">
      <GridFilterPanel
        {...props}
        disableAddFilterButton
        disableRemoveAllButton
        filterFormProps={{
          columnInputProps: {
            sx: columnInputSx,
          },
          operatorInputProps: {
            sx: operatorInputSx,
          },
          logicOperatorInputProps: {
            sx: logicOperatorInputSx,
          },
          valueInputProps: {
            InputComponentProps: {
              sx: bigRockTextFieldSx,
            },
          },
        }}
        sx={mixinSx(sharedPaperSx, gridFilterPanelSx, props.sx)}
      />
      <GridPanelFooter
        sx={{
          display: 'flex',
          gap: 1,
          flexDirection: 'row',
          paddingX: 1.5,
          paddingY: 1,
          borderTop: '1px solid',
          borderColor: (t) => t.palette.divider,
          justifyContent: 'space-between',
        }}
      >
        <Button onClick={addNewFilter} startIcon={<AddIcon />}>
          <Trans context="Button copy to add an item to a list">Add</Trans>
        </Button>
        <IconButton
          data-testid="clear-filters-button"
          isIcon
          onClick={clearFilters}
          sx={{
            color: (t) => t.palette.text.secondary,
            boxShadow: (t) =>
              getPaletteUtils(t.palette).boxShadowButton.secondary.default,
            transition: (t) =>
              t.transitions.create(
                ['box-shadow', 'color', 'background-color'],
                { duration: t.transitions.duration.short }
              ),
            '&:hover': {
              boxShadow: (t) =>
                getPaletteUtils(t.palette).boxShadowButton.destructive.hover,
              color: (t) => t.palette.error.main,
            },
            '&:focus': {
              boxShadow: (t) =>
                getPaletteUtils(t.palette).boxShadowButton.destructive.hover,
              color: (t) => t.palette.error.main,
            },
          }}
        >
          <DeleteIcon size={16} />
        </IconButton>
      </GridPanelFooter>
    </Stack>
  );
}
