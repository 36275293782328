import { Trans } from '@lingui/macro';
import {
  Divider,
  Fade,
  Slide,
  Stack,
  Typography,
  SxProps,
  StackProps,
} from '@mui/material';
import { getPaletteUtils, mixinSx } from '@watershed/style/styleUtils';
import Button from '@watershed/ui-core/components/Button';
import React from 'react';

export function BulkActionIsland({
  numSelected,
  onSelectAll,
  selectAllText,
  actions,
  customStackProps,
}: {
  numSelected: number;
  onSelectAll: () => void;
  selectAllText: React.ReactNode;
  actions: React.ReactNode;
  customStackProps?: StackProps;
}) {
  return (
    <Fade
      in={true}
      timeout={300}
      easing={{
        enter: 'cubic-bezier(0, 1.5, .8, 1)',
        exit: 'linear',
      }}
    >
      <Stack
        zIndex={10}
        sx={{
          transform: 'translateX(-50%)',
        }}
        position="fixed"
        left="50%"
        bottom={32}
      >
        <Slide
          in={true}
          direction="up"
          timeout={300}
          easing={{
            enter: 'cubic-bezier(0, 1.5, .8, 1)',
            exit: 'linear',
          }}
        >
          <Stack
            zIndex={10}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={12}
            px={3}
            py={0.75}
            bottom={32}
            marginInline="auto"
            bgcolor={(theme) => theme.palette.grey100}
            borderRadius="9999px"
            border={(theme) => `1px solid ${theme.palette.grey50}`}
            boxShadow={(theme) => getPaletteUtils(theme.palette).boxShadowMenu}
            sx={{
              transformOrigin: 'bottom center',
            }}
            minWidth="512px"
            flexWrap="nowrap"
            {...customStackProps}
          >
            <Stack
              direction="row"
              gap={1}
              divider={
                <Divider
                  sx={{
                    borderColor: (theme) => theme.palette.grey70,
                    height: 16,
                    alignSelf: 'center',
                  }}
                  orientation="vertical"
                  flexItem
                />
              }
            >
              <Typography
                whiteSpace="nowrap"
                sx={{
                  color: (theme) => theme.palette.grey10,
                  fontVariantNumeric: 'tabular-nums',
                  fontWeight: 600,
                }}
              >
                <Trans context="text to show how many items are selected in a list">
                  {numSelected} selected
                </Trans>
              </Typography>

              <Typography
                whiteSpace="nowrap"
                sx={{
                  fontWeight: 600,
                  cursor: 'pointer',
                  fontVariantNumeric: 'tabular-nums',
                  color: (theme) => theme.palette.grey10,
                }}
                onClick={onSelectAll}
              >
                {selectAllText}
              </Typography>
            </Stack>
            {actions}
          </Stack>
        </Slide>
      </Stack>
    </Fade>
  );
}

BulkActionIsland.ActionButton = function BulkActionButton({
  onClick,
  startIcon,
  disabled,
  text,
  sx,
  testId,
}: {
  onClick: () => void;
  startIcon: React.ReactNode;
  disabled?: boolean;
  text: React.ReactNode;
  sx?: SxProps;
  testId?: string;
}) {
  return (
    <Button
      sx={mixinSx(
        {
          gap: 1,
          border: (theme) => `1px solid ${theme.palette.grey70}`,
          background: (theme) => theme.palette.grey100,
          color: (theme) => theme.palette.grey10,
          boxShadow: 'none',
          '&:hover': {
            background: (theme) => theme.palette.grey80,
            color: (theme) => theme.palette.grey10,
            boxShadow: 'none',
          },
          '&&&.Mui-disabled': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            // eslint-disable-next-line @watershed/no-custom-colors
            color: 'rgb(175, 183, 200)',
          },
        },
        sx
      )}
      onClick={onClick}
      startIcon={startIcon}
      disabled={disabled}
      data-testid={testId}
    >
      {text}
    </Button>
  );
};
