import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const DatabaseIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M22 5c0-.852-.519-1.504-1.088-1.953-.581-.458-1.36-.826-2.232-1.117C16.925 1.345 14.563 1 12 1c-2.563 0-4.925.345-6.68.93-.873.29-1.651.66-2.232 1.117C2.518 3.496 2 4.148 2 5v14c0 .85.513 1.502 1.082 1.953.579.459 1.355.827 2.226 1.118C7.061 22.656 9.423 23 12 23s4.939-.344 6.692-.929c.87-.29 1.647-.66 2.226-1.118C21.487 20.502 22 19.849 22 19V5ZM4.003 4.985 4 5c0 .001 0 .006.003.015a.296.296 0 0 0 .032.066c.043.07.13.174.291.301.33.26.87.539 1.626.79C7.454 6.674 9.592 7 12 7s4.546-.327 6.048-.827c.756-.252 1.296-.53 1.626-.79.162-.128.248-.232.29-.302a.293.293 0 0 0 .033-.066A.052.052 0 0 0 20 5l-.003-.015a.293.293 0 0 0-.032-.066 1.235 1.235 0 0 0-.291-.301c-.33-.26-.87-.539-1.626-.79C16.546 3.326 14.408 3 12 3s-4.546.327-6.048.827c-.756.252-1.296.53-1.626.79a1.236 1.236 0 0 0-.29.302.296.296 0 0 0-.033.066ZM20 7.527a9.538 9.538 0 0 1-1.32.543C16.925 8.655 14.563 9 12 9c-2.563 0-4.925-.345-6.68-.93A9.537 9.537 0 0 1 4 7.527V12c0 .002 0 .007.003.018a.298.298 0 0 0 .033.067c.042.07.127.173.288.3.328.26.864.537 1.618.789 1.497.5 3.635.826 6.058.826s4.561-.326 6.058-.826c.754-.252 1.29-.53 1.618-.789.16-.127.246-.23.288-.3a.298.298 0 0 0 .033-.067A.061.061 0 0 0 20 12V7.527Zm0 7.005a9.52 9.52 0 0 1-1.308.539C16.939 15.656 14.577 16 12 16s-4.939-.344-6.692-.929A9.461 9.461 0 0 1 4 14.531V19c0 .002 0 .007.003.018a.298.298 0 0 0 .033.067c.042.07.127.173.288.3.328.26.864.537 1.618.789 1.497.5 3.635.826 6.058.826s4.561-.326 6.058-.826c.754-.252 1.29-.53 1.618-.789.16-.127.246-.23.288-.3a.298.298 0 0 0 .033-.067A.061.061 0 0 0 20 19v-4.468Z"
      clipRule="evenodd"
    />
  </Icon>
));
export default DatabaseIcon;
