import localFont from 'next/font/local';

export const jetBrainsMono = localFont({
  src: [
    {
      path: './static/JetBrainsMono-Regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: './static/JetBrainsMono-SemiBold.woff2',
      weight: '600',
      style: 'normal',
    },
  ],
});

export const messinaSans = localFont({
  src: [
    {
      path: './static/MessinaSansWeb-Regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: './static/MessinaSansWeb-SemiBold.woff2',
      weight: '600',
      style: 'normal',
    },
    {
      path: './static/MessinaSansWeb-Bold.woff2',
      weight: '700',
      style: 'normal',
    },
  ],
});

export const mackinacPro = localFont({
  src: [
    {
      path: './static/P22MackinacProMedium.woff2',
      weight: '500',
      style: 'normal',
    },
  ],
});

export function mergeIntoNextFontFamily(
  nextFont: ReturnType<typeof localFont>,
  otherFonts: Array<string>
): string {
  return [nextFont.style.fontFamily, otherFonts.join(', ')].join(', ');
}
