import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const ListFilterIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon
    ref={ref}
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <path d="M3 6h18M7 12h10m-7 6h4" />
  </Icon>
));
export default ListFilterIcon;
