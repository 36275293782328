/*
  The ErrorRegistry is intended to be a universal set of errors we expect to see
  on the Server or the Browser.

  We can serialize them over the network with an error code for consistency.

  For each error, we care whether it is logged from the Server or Browser so
  that we don't have duplicate event in systems like Sentry or Honeycomb. That
  said, we _do_ want all errors sent to all systems local logging (e.g. stdout).

  For each error, we also want to know whether it constitutes escalation to Sentry
  (i.e. if we believe it is a Programmer Error) for urgent resolution by engineering.
  All errors are sent to Honeycomb.

  For more information on errors and handling them, please refer to:
  https://www.notion.so/watershedclimate/Errors-Error-Handling-Error-Debugging-bebb118d26de4f978070d7075bf22c3b
*/

import isNotNullish from '@watershed/shared-util/isNotNullish';

export enum ErrorOrigin {
  Browser = 'Browser',
  Server = 'Server',
  All = 'All',
  None_Do_Not_Use = 'None_Do_Not_Use',
}

const errorCodes = [
  'BAD_USER_INPUT',
  'BAD_INPUT',
  'GRAPHQL_VALIDATION_FAILED',
  'GRAPHQL_PARSE_FAILED',
  'UNAUTHENTICATED',
  'CROSS_ORG',
  'FORBIDDEN',
  'INTERNAL_SERVER_ERROR',
  'UNSUPPORTED_MEDIA_TYPE',
  'METHOD_NOT_ALLOWED',
  'NETWORK',
  'NOT_FOUND',
  'CONFLICT',
  'PERSISTED_QUERY_NOT_SUPPORTED',
  'PERSISTED_QUERY_NOT_FOUND',
  'INTEGRATION_ERROR',
  'INVALID_API_RESPONSE',
  'BROWSER_PROGRAMMER_ERROR',
  'SERVER_PROGRAMMER_ERROR',
  'UNEXPECTED',
  'GONE',
  'BAD_DATA_ERROR',
  'NEXT_NAVIGATION_CANCELLATION',
  'TOO_MANY_REQUESTS',
  'DATA_RESPONSE_TOO_LARGE',
  'MESSAGE_LOADING_ERROR', // For errors loading translations for strings
] as const;
export type ErrorCode = (typeof errorCodes)[number];

/**
 * Returns a boolean indicating whether a string is a valid error code.
 */
export function isKnownErrorCode(x: string): x is ErrorCode {
  return errorCodes.some((code) => code === x);
}

export function hasKnownErrorCode(
  error: unknown
): error is { code: ErrorCode } {
  const code = getErrorCode(error);
  return isNotNullish(code) && isKnownErrorCode(code);
}

export function getErrorCode(error: unknown): string | undefined {
  if (
    error instanceof Object &&
    'code' in error &&
    typeof error.code === 'string'
  ) {
    return error.code;
  }

  if (
    error instanceof Object &&
    'extensions' in error &&
    error.extensions instanceof Object &&
    'code' in error.extensions &&
    typeof error.extensions.code === 'string'
  ) {
    return error.extensions.code;
  }
}

interface ErrorConfig {
  notifySentryFrom?: ErrorOrigin;
}

const ErrorRegistry: Record<ErrorCode, ErrorConfig> = {
  /*
   * Input Errors
   */

  /**
   * In general, BAD_USER_INPUT errors are considered operational errors and
   * should _not_ go to Sentry. However, if one is being thrown from the browser,
   * it means the browser did not correctly handle a server-side operational
   * error and we want to notify Sentry.
   */
  BAD_USER_INPUT: {
    notifySentryFrom: ErrorOrigin.Browser,
  },

  /**
   * Used when a callee considers the caller's input invalid. This is distinct
   * from BAD_USER_INPUT because it does not assume that the input came from an
   * end "user" of a browser application. For that reason, servers should notify
   * Sentry of these errors: they indicate a bug in the code.
   */
  BAD_INPUT: {
    notifySentryFrom: ErrorOrigin.Server,
  },

  /**
   * The GraphQL operation is not valid against the server's schema."
   * https://www.apollographql.com/docs/apollo-server/data/errors/#error-codes
   */
  GRAPHQL_VALIDATION_FAILED: {
    notifySentryFrom: ErrorOrigin.Browser,
  },

  /**
   * "The GraphQL operation string contains a syntax error."
   * https://www.apollographql.com/docs/apollo-server/data/errors/#error-codes
   */
  GRAPHQL_PARSE_FAILED: {
    notifySentryFrom: ErrorOrigin.Browser,
  },

  /*
   * Operational Errors
   */

  /**
   * Used when an unauthenticated user makes a request.
   */
  UNAUTHENTICATED: {
    notifySentryFrom: ErrorOrigin.Browser,
  },

  /**
   * Special case where you are logged into one org querying another.
   * Typically a Watershed employee
   */
  CROSS_ORG: {
    notifySentryFrom: ErrorOrigin.Browser,
  },

  /**
   * Used when the user is trying to do something they do not have permission to
   * do.
   *
   * We should only surface in-product links to parts of the product the user can
   * access, but it is possible that they have received or cached URLs for pages
   * they are not allowed to access. We do not want to page on these.
   * See https://app.datadoghq.com/monitors/92409722 for ForbiddenError monitoring.
   */
  FORBIDDEN: {
    notifySentryFrom: ErrorOrigin.Browser,
  },

  METHOD_NOT_ALLOWED: {
    notifySentryFrom: ErrorOrigin.Browser,
  },

  UNSUPPORTED_MEDIA_TYPE: {
    notifySentryFrom: ErrorOrigin.Browser,
  },

  /**
   * Servers send this code to clients when they've hit an internal error and
   * cannot respond correctly.
   */
  INTERNAL_SERVER_ERROR: {
    notifySentryFrom: ErrorOrigin.Server,
  },

  /**
   * Clients use this code when they can't do their job because of networking
   * problems when communicating with servers. We do not notify sentry here,
   * because they are often not individually actionable. Instead we have a
   * honeycomb trigger tracking this metric.
   */
  NETWORK: {},

  /**
   * Used when a client is trying to access a resource that does not exist.
   */
  NOT_FOUND: {
    notifySentryFrom: ErrorOrigin.Browser,
  },

  /**
   * Used when a client is trying to perform an operation that conflicts with
   * the current state on the server. For example, trying to publish a footprint
   * snapshot while another job is also publishing the same snapshot.
   *
   * We should NOT notify sentry if thrown from the server, since the error
   * should surfaced to the browser/user to indicate a bad request, rather than
   * handled by engineers.
   */
  CONFLICT: {
    notifySentryFrom: ErrorOrigin.Browser,
  },

  /**
   * "A client sent the hash of a query string to execute via automatic
   * persisted queries, but the query was not in the APQ cache."
   * https://www.apollographql.com/docs/apollo-server/data/errors/#error-codes
   */
  PERSISTED_QUERY_NOT_SUPPORTED: {
    notifySentryFrom: ErrorOrigin.Server,
  },

  /**
   * "A client sent the hash of a query string to execute via automatic
   * persisted queries, but the server has disabled APQ."
   * https://www.apollographql.com/docs/apollo-server/data/errors/#error-codes
   */
  PERSISTED_QUERY_NOT_FOUND: {
    notifySentryFrom: ErrorOrigin.Server,
  },

  // API: Schema version no longer supported
  GONE: {},

  /*
   * Programmer Errors
   */

  /**
   * Used when a client determines that an API response has an unexpected shape.
   * We only call this after scanning for all the usual errors, so this often
   * reflects a programmer error, like an unexpected null value.
   */
  INVALID_API_RESPONSE: {
    notifySentryFrom: ErrorOrigin.Browser,
  },

  /**
   * This is a catch-all for unexpected errors in browser apps that should not
   * happen if the code were 100% correct.
   */
  BROWSER_PROGRAMMER_ERROR: {
    notifySentryFrom: ErrorOrigin.Browser,
  },

  /**
   * This is a catch-all for unexpected errors in server apps that should not
   * happen if the code were 100% correct.
   */
  SERVER_PROGRAMMER_ERROR: {
    notifySentryFrom: ErrorOrigin.Server,
  },

  /**
   * Catch-all for unexpected errors where we are unable to or uninterested in
   * categorizing it more precisely.
   */
  UNEXPECTED: {
    notifySentryFrom: ErrorOrigin.Server,
  },

  /**
   * This is the right error to throw if the server code is correct but the data
   * retrieved from the database is not. It is a signal that the code we use to
   * load data — or the constraints we intend to enforce on the data — are not
   * being met
   */
  BAD_DATA_ERROR: {
    notifySentryFrom: ErrorOrigin.Server,
  },

  /**
   * Error when calling an Integration Partner Api
   */
  INTEGRATION_ERROR: {
    notifySentryFrom: ErrorOrigin.Server,
  },

  /**
   * A special class of error for navigation cancellation. Next.js does not
   * support global navigation cancellation (https://github.com/vercel/next.js/discussions/12348#discussioncomment-131768).
   * The only way to prevent navigation is a hack which throws an exception
   * while processing a router event.
   *
   * Since we're stuck using that hack currently, we need a way to not cause
   * undue noise in sentry, hence this hack.
   */
  NEXT_NAVIGATION_CANCELLATION: {
    notifySentryFrom: ErrorOrigin.None_Do_Not_Use,
  },

  TOO_MANY_REQUESTS: {},
  DATA_RESPONSE_TOO_LARGE: {},
  MESSAGE_LOADING_ERROR: { notifySentryFrom: ErrorOrigin.All },
} as const;

export function shouldSendErrorToSentry(
  platform: 'Browser' | 'Server',
  code?: string
): boolean {
  if (!code || !isKnownErrorCode(code)) {
    return true;
  }
  const notifySentryFrom = ErrorRegistry[code].notifySentryFrom;
  return notifySentryFrom === 'All' || notifySentryFrom === platform;
}

export default ErrorRegistry;
