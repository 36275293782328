import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const ToolIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M16.558 3.028a5 5 0 0 0-5.117 7.03 1 1 0 0 1-.204 1.12l-6.91 6.91a1.121 1.121 0 0 0 1.586 1.585l6.91-6.91a1 1 0 0 1 1.12-.204 5 5 0 0 0 7.029-5.117L18.4 10.014a2 2 0 0 1-2.8 0l-.007-.007L13.986 8.4a2 2 0 0 1 0-2.8l.007-.007 2.565-2.565ZM14.742 1.11a7 7 0 0 1 4.14.509 1 1 0 0 1 .295 1.618L15.414 7 17 8.586l3.763-3.763a1 1 0 0 1 1.618.295 7 7 0 0 1-8.606 9.521l-6.448 6.448a3.122 3.122 0 0 1-4.414-4.414l6.448-6.448a7 7 0 0 1 5.38-9.115Z"
      clipRule="evenodd"
    />
  </Icon>
));
export default ToolIcon;
