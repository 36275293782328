import React, { useState, useId, useEffect } from 'react';
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  PopoverOrigin,
} from '@mui/material';

export type PopoverMenuProps = React.ComponentProps<typeof PopoverMenu>;

/** @deprecated Use DropdownMenu instead */
export default function PopoverMenu({
  trigger,
  options,
  anchorOrigin = {
    horizontal: -36,
    vertical: 36,
  },
  transformOrigin,
  onVisibilityChange,
}: {
  trigger: React.ReactElement<React.HTMLAttributes<HTMLElement>>;
  options: Array<{
    title: React.ReactNode;
    subtitle?: LocalizedString;
    onClick: React.MouseEventHandler<HTMLLIElement>;
    id?: string;
    testId?: string;
    icon?: React.ReactNode;
  }>;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  onVisibilityChange?(isVisible: boolean): void;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const menuId = useId();
  const triggerWithOnClick = React.cloneElement(trigger, {
    onClick: (evt: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(evt.currentTarget);
    },
    'aria-controls': menuId,
    'aria-haspopup': true,
  });

  const isOpen = !!anchorEl;

  // notify onVisibilityChange when the menu is opened or closed
  useEffect(() => {
    onVisibilityChange?.(isOpen);
  }, [isOpen, onVisibilityChange]);

  return (
    <>
      {triggerWithOnClick}
      <Menu
        id={menuId}
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        {options.map((option, i) => (
          <MenuItem
            key={option.id ?? i}
            data-testid={option.testId}
            onClick={(evt) => {
              option.onClick(evt);
              setAnchorEl(null);
            }}
          >
            {option.icon && (
              <ListItemIcon
                sx={{
                  minWidth: 'unset',
                  marginRight: 1,
                  '& svg': { width: 16, height: 16 },
                }}
              >
                {option.icon}
              </ListItemIcon>
            )}
            <ListItemText primary={option.title} secondary={option.subtitle} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
