import { forwardRef } from 'react';
import {
  SxProps,
  Theme,
  // eslint-disable-next-line no-restricted-imports
  CircularProgress as MuiCircularProgress,
  CircularProgressProps as MuiCircularProgressProps,
  Box,
} from '@mui/material';
import omit from 'lodash/omit';
import { mixinSx } from '@watershed/style/styleUtils';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';

export interface CircularProgressProps
  extends Omit<MuiCircularProgressProps, 'tw'> {
  hasOutline?: boolean; // show the whole circle, as well
  outlineColor?: MuiCircularProgressProps['color'];
  outlineSx?: SxProps<Theme>;
}

export default forwardRef(function CircularProgress(
  props: CircularProgressProps,
  ref: React.Ref<HTMLDivElement | HTMLSpanElement>
): JSX.Element {
  const muiProps = omit(props, 'hasOutline', 'outlineColor', 'outlineSx');
  const testId =
    props.variant === 'determinate'
      ? TestIds.CircularProgressDeterminate
      : TestIds.LoadingStateCircularProgress;

  if (props.hasOutline) {
    return (
      <Box
        data-testid={testId}
        sx={props.sx}
        height={props.size ?? 40}
        width={props.size ?? 40}
        position="relative"
        ref={ref}
      >
        <MuiCircularProgress
          {...muiProps}
          variant="determinate"
          value={100}
          color={props.outlineColor ?? 'secondary'}
          sx={mixinSx(
            {
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            },
            props.outlineSx
          )}
        />
        <MuiCircularProgress
          {...muiProps}
          sx={mixinSx(
            {
              display: 'block',
            },
            props.sx
          )}
        />
      </Box>
    );
  }
  return <MuiCircularProgress ref={ref} data-testid={testId} {...muiProps} />;
});
