import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const ChevronLeftIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M15.707 5.293a1 1 0 0 0-1.414 0l-6 6a1 1 0 0 0 0 1.414l6 6a1 1 0 0 0 1.414-1.414L10.414 12l5.293-5.293a1 1 0 0 0 0-1.414Z"
      clipRule="evenodd"
    />
  </Icon>
));
export default ChevronLeftIcon;
