import {
  CustomErrorInvariant,
  makeCustomErrorInvariant,
  makeRethrower,
  WatershedError,
  WatershedErrorOptions,
} from './WatershedError';

export class UnsupportedMediaTypeError extends WatershedError {
  status = 415;
  constructor(message?: string, options?: WatershedErrorOptions) {
    super('UNSUPPORTED_MEDIA_TYPE', message, options);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UnsupportedMediaTypeError);
    }
    Object.setPrototypeOf(this, UnsupportedMediaTypeError.prototype);
  }

  static invariant: CustomErrorInvariant = makeCustomErrorInvariant(
    UnsupportedMediaTypeError
  );
  static wrapAndRethrow = makeRethrower(UnsupportedMediaTypeError);
}
