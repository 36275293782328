import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const CashIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M9 12a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3 1a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M20 19a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H4a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h16Zm0-12h-1.686L21 9.686V8a1 1 0 0 0-1-1ZM8.314 7h7.171l4.9 4.9-5.1 5.1h-6.77l-5.1-5.1L8.313 7ZM4 7h1.485L3 9.485V8a1 1 0 0 1 1-1Zm-1 9v-1.686L5.686 17H4a1 1 0 0 1-1-1Zm18-1.887L18.113 17H20a1 1 0 0 0 1-1v-1.887Z"
      clipRule="evenodd"
    />
  </Icon>
));
export default CashIcon;
