import {
  makeCustomErrorInvariant,
  CustomErrorInvariant,
  WatershedErrorOptions,
  WatershedError,
  makeRethrower,
} from './WatershedError';

export class DataResponseTooLarge extends WatershedError {
  status = 400;
  constructor(message?: string, options?: WatershedErrorOptions) {
    super('DATA_RESPONSE_TOO_LARGE', message, options);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, DataResponseTooLarge);
    }
    Object.setPrototypeOf(this, DataResponseTooLarge.prototype);
  }

  static invariant: CustomErrorInvariant =
    makeCustomErrorInvariant(DataResponseTooLarge);
  static wrapAndRethrow = makeRethrower(DataResponseTooLarge);
}
