import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const LoopedSquareIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M3.172 3.172A4 4 0 0 1 10 6v2h4V6a4 4 0 1 1 4 4h-2v4h2a4 4 0 1 1-4 4v-2h-4v2a4 4 0 1 1-4-4h2v-4H6a4 4 0 0 1-2.828-6.828ZM8 8V6a2 2 0 1 0-2 2h2Zm2 2v4h4v-4h-4Zm-2 6H6a2 2 0 1 0 2 2v-2Zm8 0v2a2 2 0 1 0 2-2h-2Zm0-8h2a2 2 0 1 0-2-2v2Z"
      clipRule="evenodd"
    />
  </Icon>
));
export default LoopedSquareIcon;
