import { Theme, Typography, Grid, GridSize } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import clsx from 'clsx';
import chunk from 'lodash/chunk';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dl: {
      margin: 0,
    },
    row: {
      display: 'flex',
      alignItems: 'baseline',
      '&.showBorders + $row': {
        borderTop: `1px solid ${theme.palette.divider}`,
      },
    },
    dt: {
      flexShrink: 0,
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      overflowWrap: 'break-word',
      '&.dense': {
        fontSize: 13,
        lineHeight: 1.25,
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
      },
    },
    dd: {
      margin: 0,
      flexGrow: 1,
      overflow: 'auto',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      '&.dense': {
        fontSize: 13,
        lineHeight: 1.25,
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
      },
    },
  })
);

export interface IRenderValue {
  (key: string, value: any, data: Map<string, any>): React.ReactNode | null;
}

const preJsonRenderValue: IRenderValue = (key, value, data) => {
  return <pre>{JSON.stringify(value, null, 2)}</pre>;
};

const defaultRenderValue: IRenderValue = (key, value, data) => {
  if (React.isValidElement(value)) return value;
  if (value !== null && typeof value === 'object') {
    return preJsonRenderValue(key, value, data);
  }
  if (value !== null && typeof value === 'boolean') {
    return value ? 'true' : 'false';
  }
  return value;
};

/**
 * A list of key-value pairs presented as rows.
 */
export default function KeyValueRows({
  data,
  renderValue = defaultRenderValue,
  displayNullValues = false,
  displayUndefinedValues = false,
  keyWidth = 200,
  valueMaxHeight = 240,
  paddingX = 0,
  isDense = false,
  columnCount = 1,
  includeBorders = true,
}: {
  /**
   * Key-value pairs are provided as a Map so the caller has control over the
   * order in which pairs will be rendered.
   */
  data: Map<string, any>;
  /**
   * A function for customizing how values are rendered. For each row, receives
   * the row's key, row's value, and the Map passed in as `data`. To skip
   * rendering that row altogether, return `null`. Otherwise, return a React
   * node.
   */
  renderValue?: IRenderValue;
  /**
   * If `false`, items in `data` will be filtered out if their value is
   * `null`.
   */
  displayNullValues?: boolean;
  /**
   * If `false`, items in `data` will be filtered out if their value is
   * `undefined`.
   */
  displayUndefinedValues?: boolean;
  /**
   * The width of for name elements.
   */
  keyWidth?: string | number;
  /**
   * The maximum height for value elements. Overflow will scroll.
   */
  valueMaxHeight?: string | number;
  /**
   * Padding for the left and right of each row. This can be used to extend the
   * border between rows beyond the contents.
   */
  paddingX?: string | number;
  /**
   * If true, the rendering has smaller text and less whitespace.
   */
  isDense?: boolean;
  /**
   * Key-value pairs will be divided evenly across the columns. This division
   * only accounts for the key-value pair count, not for the height of rendered
   * values; so if rendered values can have significantly different heights,
   * the columns won't appear balanced.
   */
  columnCount?: 1 | 2 | 3;
  /**
   * If false, there are no borders between rows.
   */
  includeBorders?: boolean;
}) {
  const classes = useStyles();

  const rows = Array.from(data)
    .filter(
      ([k, v]) =>
        (displayNullValues || v !== null) &&
        (displayUndefinedValues || v !== undefined) &&
        k !== '__typename'
    )
    .flatMap(([k, v]) => {
      const rendered = renderValue(k, v, data);
      if (rendered == null) {
        return [];
      }
      return [
        <div
          key={k}
          className={clsx(
            classes.row,
            isDense && 'dense',
            includeBorders && 'showBorders'
          )}
        >
          <Typography
            component="dt"
            variant="body2"
            className={clsx(classes.dt, isDense && 'dense')}
            style={{ width: keyWidth, paddingLeft: paddingX }}
          >
            {k.trim()}
          </Typography>
          <Typography
            component="dd"
            variant="body1"
            className={clsx(classes.dd, isDense && 'dense')}
            style={{ maxHeight: valueMaxHeight, paddingRight: paddingX }}
          >
            {rendered}
          </Typography>
        </div>,
      ];
    });

  const rowsByColumn = chunk(rows, Math.ceil(rows.length / columnCount));
  const renderedColumns = rowsByColumn.map((columnRows, index) => {
    return (
      <Grid key={index} item xs={(12 / columnCount) as GridSize}>
        <dl className={classes.dl}>{columnRows}</dl>
      </Grid>
    );
  });

  return (
    <Grid container spacing={4}>
      {renderedColumns}
    </Grid>
  );
}
