import React, { ChangeEvent, ReactNode } from 'react';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material';
import { createStyles, makeStyles, ClassNameMap } from '@mui/styles';
import clsx from 'clsx';
import Field, { filterFieldInputProps } from './Field';
import type { FieldProps } from './Field';
import { useField } from 'formik';
import assertIncluded from '@watershed/shared-universal/utils/assertIncluded';

const useStyles = makeStyles((theme) =>
  createStyles({
    inputWarning: {
      // What would a radio warning state look like?
    },
    inputError: {
      // What would a radio error state look like?
    },
  })
);

interface RadioFieldProps<T extends string | number>
  extends Omit<FieldProps, 'inputId'>,
    Omit<RadioGroupProps, 'ref' | 'value' | 'onChange'> {
  id: string;
  value?: T | null;
  options: Array<{
    value: T;
    label: ReactNode;
    disabled?: boolean;
    children?: ReactNode;
  }>;
  onChange?: (event: ChangeEvent<HTMLInputElement>, value: T) => void;
}

function getInputProps<T extends string | number>(
  props: RadioFieldProps<T>,
  classes: ClassNameMap
) {
  const {
    id,
    validationState,
    validationMessage,
    sublabel,
    required,
    onChange,
  } = props;
  return {
    className: clsx(
      validationState === 'error' && classes.inputError,
      validationState === 'warning' && classes.inputWarning
    ),
    id,
    'aria-invalid': validationState === 'error',
    'aria-required': required,
    'aria-describedby': clsx(
      sublabel && `${id}-sublabel`,
      validationMessage && `${id}-validationMessage`
    ),
    onChange: onChange
      ? (event: ChangeEvent<HTMLInputElement>, value: string) =>
          onChange(event, value as T)
      : undefined,
  };
}

export default function RadioField<T extends string | number>({
  className,
  ...rawProps
}: RadioFieldProps<T>) {
  const classes = useStyles();

  const [formikProps, meta] = useField({
    name: rawProps.name ?? rawProps.id,
    value: rawProps.value ?? undefined,
  });

  const props: RadioFieldProps<T> = {
    validationState: meta.touched && meta.error ? 'error' : 'default',
    validationMessage: meta.touched && meta.error ? meta.error : undefined,
    ...rawProps,
  };

  // Can't use useMemo here because props.options could change while being
  // referentially equal.
  const optionValues = props.options.map((option) => option.value);

  return (
    <Field {...props} inputId={props.id} className={className}>
      <RadioGroup
        {...filterFieldInputProps(props)}
        {...getInputProps(props, classes)}
        {...formikProps}
        onChange={(event, value) => {
          formikProps.onChange(event);
          assertIncluded(value, optionValues);
          if (rawProps.onChange) rawProps.onChange(event, value);
        }}
        onBlur={(event) => {
          formikProps.onBlur(event);
          if (rawProps.onBlur) rawProps.onBlur(event);
        }}
      >
        {props.options.map((option) => {
          return (
            <React.Fragment key={option.value}>
              <FormControlLabel
                data-testid={`radioField-${props.id}-${option.value}`}
                key={option.value}
                value={option.value}
                label={option.label}
                control={<Radio />}
                disabled={option.disabled}
              />
              {option.children}
            </React.Fragment>
          );
        })}
      </RadioGroup>
    </Field>
  );
}

export function RadioFieldNonFormik<T extends string>({
  className,
  ...props
}: RadioFieldProps<T>) {
  const classes = useStyles();
  return (
    <Field {...props} inputId={props.id} className={className}>
      <RadioGroup
        {...filterFieldInputProps(props)}
        {...getInputProps(props, classes)}
      >
        {props.options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            label={option.label}
            disabled={option.disabled}
            control={<Radio />}
          />
        ))}
      </RadioGroup>
    </Field>
  );
}
