import { z } from 'zod';
import { GQCanonicalDatasetKind } from '../generated/graphql';
import { socialAndGovernanceTypeNames } from '../batSchemas/types';

import { msg } from '@lingui/core/macro';
import { MessageDescriptor } from '@lingui/core';

export const DEFAULT_EM_DESCRIPTION = `EM run generated from product`;
export const DEFAULT_FOOTPRINT_DESCRIPTION = `Footprint snapshot generated from product`;
export const HISTORICAL_EMISSIONS_BAT = 'historical_emissions';
export const HISTORICAL_EMISSIONS_PV2_BAT = 'historical_emissions_pv2';

// Where did we get the number 50,000? Assuming the dedicated task queue takes
// 30-90s to spin up, we think 50,000 is a reasonable lower limit because (as of
// 2024-09-04) its p50 run time is almost 8 minutes, so users are unlikely to be
// disturbed by an extra 30-90s.
export const DEDICATED_TASK_QUEUE_MIN_INPUT_ROW_COUNT = 50_000;

// We're seeing that (as of 2024-10-06) `createFootprintSnapshowWorkflow` runs
// has a p50 run time of 6 minutes for 1M rows, so users are unlikely to be
// disturbed by an extra 30-90s.
export const DEDICATED_TASK_QUEUE_MIN_PLR_ROW_COUNT = 1_000_000;

// Max BARTS per EM run. This is to prevent us from hitting
// temporal workflow history limits and message size limits, though
// we have not done any testing to see what the actual limits are.
export const MAX_BARTS_PER_EM_RUN = 100;

// Max BARTS per batched EM run.  The batched workflow lets us advance past the
// 100 BART limit, but still limit the number of BARTs we run in one flow to
// 2000 so as not to overwhelm downstream calculations.
export const MAX_BARTS_PER_BATCHED_EM_RUN = 2000;

// Max historical_emissions BARTs per chunked createPrecalculatedEmissionsWorkflow
// run.
export const MAX_HISTORICAL_EMISSIONS_BARTS_PER_CHUNK = 10;

// As of 2024-10-23, we're considering this a reasonable limit that both saves
// us from scaling problems and doesn't substantially inconvenience (hardly any)
// customers.
export const MAX_PLR_ROWS_PER_FPS = 50_000_000;

export const FOOTPRINT_SNAPSHOT_DRILLDOWN_PARAM = 'footprintSnapshotId';
export const FOOTPRINT_SNAPSHOT_IDS_DRILLDOWN_PARAM = 'footprintSnapshotIds';
export const DRILLDOWN_DRAWER_PARAM = 'drawer';

// to deprecate
export const ACTIVITY_DRAWER_QUERY_PARAM = 'viewActivity';
export const FOOTPRINT_QUESTION_QUERY_PARAM = 'footprintQuestion';

// These are NOT emissive, NOT used for intensity calculation, and we don't want
// them to be associated with a footprint.
export const DATASETS_TO_IGNORE_FOR_FOOTPRINT: Array<GQCanonicalDatasetKind> = [
  GQCanonicalDatasetKind.ChartOfAccounts,
];

export const INTENSITY_DENOMINATOR_BATS = [
  'intensity_denominator_custom',
  'intensity_denominator_monetary',
];

// A list of BATs that will be associated with a footprint but are non-emissive.
// (if adding to this list: don't forget to update NON_EMISSIVE_DATASET_KINDS in CanonicalDatasetUtils.ts too)
export const NON_EMISSIVE_ACTIVITY_BATS = [
  'site_land_use',
  'sold_product_circularity',
  'sold_product_recyclability',
  'carbon_credit_retirements',
  'ghg_removal',
  ...socialAndGovernanceTypeNames,
];

export const NON_EMISSIVE_BATS = [
  ...INTENSITY_DENOMINATOR_BATS,
  ...NON_EMISSIVE_ACTIVITY_BATS,
];

// order matters
export const FOOTPRINT_CONFIGURATION_DRAWER_TABS_SCHEMA = z.enum([
  'general',
  'datasets',
  'calculation',
  'category-rules',
  'deduplication-rules',
  'clean-power',
]);
export const FOOTPRINT_CONFIGURATION_DRAWER_TABS =
  FOOTPRINT_CONFIGURATION_DRAWER_TABS_SCHEMA.enum;
export type FootprintConfigurationDrawerTab = z.infer<
  typeof FOOTPRINT_CONFIGURATION_DRAWER_TABS_SCHEMA
>;

export const SAVED_VIEW_DRAFT_ID = 'draft'; // Used as the value for an overflow/other category group name throughout drilldown + reductions
// Should not be displayed as is

export const FOOTPRINT_USER_ERROR_TYPES_SCHEMA = z.enum([
  'FootprintTooLargeForCleanPowerAllocation',
  'NoEmissiveDatasets',
  'CustomEmissionsActivityMismatch',
  'CleanPowerInstructionsOverBudget',
  'AlphaCalculationMethod',
  'FootprintTooLarge',
  'Unknown',
]);
export const FootprintUserError = FOOTPRINT_USER_ERROR_TYPES_SCHEMA.enum;

export const FOOTPRINT_USER_ERROR_DETAILS_SCHEMA = z.discriminatedUnion(
  'type',
  [
    z.object({
      type: z.literal(
        FootprintUserError.FootprintTooLargeForCleanPowerAllocation
      ),
    }),
    z.object({
      type: z.literal(FootprintUserError.NoEmissiveDatasets),
    }),
    z.object({
      type: z.literal(FootprintUserError.CustomEmissionsActivityMismatch),
      dateRange: z.string(),
      emissionsSpecifier: z.string(),
      measurementProjectId: z.string(),
      measurementProjectName: z.string().optional(),
    }),
    z.object({
      type: z.literal(FootprintUserError.CleanPowerInstructionsOverBudget),
      instructions_mwh: z.string(),
      budget_mwh: z.string(),
    }),
    z.object({
      type: z.literal(FootprintUserError.AlphaCalculationMethod),
      businessActivityTypeName: z.string(),
      displayName: z.string(),
    }),
    z.object({
      type: z.literal(FootprintUserError.FootprintTooLarge),
    }),
    z.object({
      type: z.literal(FootprintUserError.Unknown),
    }),
  ]
);
export type FootprintUserErrorDetails = z.infer<
  typeof FOOTPRINT_USER_ERROR_DETAILS_SCHEMA
>;

export const LEGACY_FOOTPRINT_NAME = 'Legacy Footprint';
export const LEGACY_FOOTPRINTS_NO_EDIT_WARNING: MessageDescriptor = msg`Legacy footprints can't be edited`;
export const FOOTPRINT_NAME_MUST_TRIM_ERROR_MSG: MessageDescriptor = msg`Footprint name cannot start or end with whitespace`;
export const FOOTPRINT_NAME_NOT_UNIQUE_ERROR_MSG: MessageDescriptor = msg`Footprint name is already used`;
export const FOOTPRINT_NAME_NOT_EMPTY_ERROR_MSG: MessageDescriptor = msg`Footprint name cannot be empty`;

export const MAX_CONCURRENT_FOOTPRINT_GENERATION_TOOLTIP_MSG: MessageDescriptor = msg`Unable to create or edit footprints while another footprint is being generated. Please try again in a few minutes.`;
export const MAX_CONCURRENT_FOOTPRINT_GENERATION = 1;
export const MAX_FOOTPRINT_GENERATION_RUNTIME_MILLISECONDS = 3 * 60 * 60 * 1000; // 3 hours
export const MILLISECONDS_TO_WAIT_FOR_WORKFLOW_TO_START = 3 * 60 * 1000; // 3 minutes

// this is 40 files of 250k rows each
export const FOOTPRINT_AUDIT_BUNDLE_MAXIMUM_ROW_COUNT = 10_000_000;

// This is maintained in a const so that if we get rid of the tour dialog and thereby also
// this const, engineers will also know to remove this operation in the e2e tests
export const MULTIPLE_FOOTPRINTS_TOUR_ALT =
  'Footprints are now a lot more flexible';
