import {
  CustomErrorInvariant,
  makeCustomErrorInvariant,
  makeRethrower,
  WatershedError,
  WatershedErrorOptions,
} from './WatershedError';

export class MethodNotAllowedError extends WatershedError {
  status = 405;
  constructor(message?: string, options?: WatershedErrorOptions) {
    super('METHOD_NOT_ALLOWED', message, options);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, MethodNotAllowedError);
    }
    Object.setPrototypeOf(this, MethodNotAllowedError.prototype);
  }

  static invariant: CustomErrorInvariant = makeCustomErrorInvariant(
    MethodNotAllowedError
  );
  static wrapAndRethrow = makeRethrower(MethodNotAllowedError);
}
