import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import {
  CustomContentProps,
  MaterialDesignContent,
  SnackbarProvider,
} from 'notistack';
import { forwardRef } from 'react';

const CustomErrorComponent = forwardRef<HTMLDivElement, CustomContentProps>(
  (props, ref) => (
    <div ref={ref} data-testid={TestIds.ErrorStateSnackbar}>
      <MaterialDesignContent {...props} />
    </div>
  )
);

function WatershedSnackbarProvider(props: any) {
  return (
    <SnackbarProvider
      maxSnack={4}
      autoHideDuration={3000}
      Components={{
        error: CustomErrorComponent,
      }}
    >
      {props.children}
    </SnackbarProvider>
  );
}

export { WatershedSnackbarProvider };
