import Box, { type BoxProps } from '@mui/material/Box';
import {
  ComponentPropsWithRef,
  forwardRef,
  ForwardRefExoticComponent,
} from 'react';

export type IconProps = BoxProps &
  Omit<ComponentPropsWithRef<'svg'>, 'color'> & {
    size?: number | string;
  };

export type IconElementWithRef = ForwardRefExoticComponent<
  IconProps & React.RefAttributes<SVGSVGElement>
>;

export type IconElement = IconElementWithRef | React.ComponentType<IconProps>;

const Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 20, ...props }, ref) => (
    <Box
      component="svg"
      width={size}
      height={size}
      aria-hidden={props['aria-label'] ? undefined : true}
      ref={ref}
      {...props}
    />
  )
);

export default Icon;
