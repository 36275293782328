import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const ChevronsRightIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M5.293 6.293a1 1 0 0 1 1.414 0l5 5a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414-1.414L9.586 12 5.293 7.707a1 1 0 0 1 0-1.414Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M12.293 6.293a1 1 0 0 1 1.414 0l5 5a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414-1.414L16.586 12l-4.293-4.293a1 1 0 0 1 0-1.414Z"
      clipRule="evenodd"
    />
  </Icon>
));
export default ChevronsRightIcon;
