import { BadInputError } from '@watershed/errors/BadInputError';
import { WatershedError } from '@watershed/errors/WatershedError';
import { bulletList } from '../utils/helpers';

export interface ValidationResult {
  isValid: boolean;
  errors: Array<string>;
  didReachMaxErrors?: boolean;
}

/**
 * A data structure for representing possible combinations of enum or boolean values.
 * For example:
 *  {
 *    fields: ['fuel_kind', 'unit'],
 *    combinations: [
 *      ['Gasoline', 'gallon'],
 *      ['Coal', 'kilogram'],
 *    ]
 *  },
 *  {
 *    fields: ['product_category', 'is_energy_star'],
 *    combinations: [
 *      ['Refrigerator', true],
 *      ['Clock', false],
 *    ]
 *  },
 */
export type EnumCombinations = {
  fields: Array<string>;
  combinations: Array<Array<string | boolean | null>>;
};

export class EnumCombinationValidator {
  // Set of serialized enum combinations
  validCombinations: Set<string | boolean>;
  fieldNames: Array<string>;

  constructor(validCombinations: EnumCombinations) {
    BadInputError.invariant(
      validCombinations.combinations.length > 0,
      'Cannot initialize EnumCombinationValidator with an empty combinations array'
    );

    this.validCombinations = new Set();
    this.fieldNames = validCombinations.fields;

    for (const combination of validCombinations.combinations) {
      BadInputError.invariant(
        combination.length === this.fieldNames.length,
        'Enum combination entry has wrong length',
        { data: { fieldNames: this.fieldNames, combination } }
      );
      this.validCombinations.add(JSON.stringify(combination));
    }
  }

  validateRecord(record: Record<string, any>): boolean {
    const relevantValues = this.fieldNames.map(
      (fieldName) => record[fieldName] ?? null
    );
    return this.validCombinations.has(JSON.stringify(relevantValues));
  }
}

/**
 * Callers can check for this error type and decide whether it's truly
 * unexpected or should be recast as a UserInputError.
 */
export class CanonicalSchemaValidationError extends WatershedError {
  name = 'CanonicalSchemaValidationError';

  constructor(
    message: string,
    public details?: { validationResult?: ValidationResult }
  ) {
    super('BAD_INPUT', message);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CanonicalSchemaValidationError);
    }
    Object.setPrototypeOf(this, CanonicalSchemaValidationError.prototype);
  }

  static fromValidationResult(
    validationResult: ValidationResult,
    stableId?: string
  ): CanonicalSchemaValidationError {
    let message = `Validation failure${
      stableId ? ` for BART with stableId ${stableId}` : ''
    }:\n${bulletList(validationResult.errors)}`;
    if (validationResult.didReachMaxErrors) {
      message += '\n- and more ...';
    }
    return new CanonicalSchemaValidationError(message, { validationResult });
  }
}
