import { Theme, SxProps, Box, Typography, Stack } from '@mui/material';
import CloseIcon from '@watershed/icons/components/Close';
import { IconElement } from '@watershed/icons/Icon';
import { mixinSx } from '@watershed/style/styleUtils';
import IconButton from './IconButton';
import { WatershedButtonProps } from '../utils/createWatershedButton';
import CustomReactMarkdown from './CustomReactMarkdown';
import React, { ReactNode, forwardRef } from 'react';
import ProgressButton, { ProgressButtonProps } from './ProgressButton';
import { ResponsiveStyleValue } from '@mui/system/styleFunctionSx';

export type Variant =
  | 'info'
  | 'success'
  | 'warning'
  | 'error'
  | 'primary'
  | 'light';

const VARIANT_STYLES: Record<Variant, SxProps<Theme>> = {
  light: {
    backgroundColor: (theme) => theme.palette.grey05,
    borderColor: (theme) => theme.palette.grey20,
  },
  info: {
    backgroundColor: (theme) => theme.palette.grey05,
    borderColor: (theme) => theme.palette.grey30,
  },
  success: {
    backgroundColor: (theme) => theme.palette.success.light,
    borderColor: (theme) => theme.palette.success.main,
  },
  warning: {
    backgroundColor: (theme) => theme.palette.warning.light,
    borderColor: (theme) => theme.palette.warning.main,
  },
  error: {
    backgroundColor: (theme) => theme.palette.error.light,
    borderColor: (theme) => theme.palette.error.main,
  },
  primary: {
    backgroundColor: (theme) => theme.palette.cobalt05,
    borderColor: (theme) => theme.palette.cobalt40,
  },
};

const VARIANT_ICON_COLOR: Record<Variant, string> = {
  light: 'secondary.dark',
  info: 'secondary.dark',
  success: 'success.dark',
  warning: 'warning.dark',
  error: 'error.dark',
  primary: 'primary.main',
};

type CommonCalloutProps = {
  title?: LocalizedString | ReactNode;
  isDense?: boolean;
  description?: ReactNode;
  actionLabel?: ReactNode;
  actionButtonProps?: Omit<
    WatershedButtonProps<ProgressButtonProps>,
    'children' | 'isInProgress'
  > & { ref?: React.RefObject<HTMLButtonElement> };
  actionInProgress?: boolean;
  customAction?: ReactNode;
  IconComponent?: IconElement;
  variant?: Variant;
  onDismiss?: () => void;
  sx?: SxProps<Theme>;
  iconSx?: SxProps<Theme>;
  dataTest?: string;
  actionGap?: ResponsiveStyleValue<string | number>;
  // Only used when variant = 'error'. Allows suppressing error logging to
  // Honeycomb if this error is already handled in-product.
  // WARNING: only use this prop if you know what you are doing - many alerts
  // are configured to depend on the error metric which is emitted by this
  // component, suppressing this event can cause significant gaps in
  // observability. If possible, filter / throttle in honeycomb to remove
  // certain events, rather than suppressing the event entirely.
  dangerouslySuppressErrorLogging?: boolean;
};

type CalloutPropsWithTitle = {
  title: LocalizedString | ReactNode;
} & CommonCalloutProps;

type CalloutPropsWithDescription = {
  description: ReactNode;
} & CommonCalloutProps;

export type CalloutProps = CalloutPropsWithTitle | CalloutPropsWithDescription;

export default forwardRef(function Callout(
  {
    title,
    description,
    IconComponent,
    iconSx,
    variant = 'info',
    isDense,
    onDismiss,
    actionLabel,
    actionButtonProps,
    actionInProgress,
    sx,
    dataTest,
    customAction,
  }: CalloutProps,
  ref
) {
  const showAction = actionLabel && actionButtonProps;
  return (
    <Box
      ref={ref}
      data-testid={dataTest ?? `callout-${variant}`}
      columnGap={1}
      display="flex"
      flexDirection="row"
      sx={mixinSx(
        {
          paddingBlock: isDense ? 1 : 1.5,
          paddingInline: 1.5,
          border: '1px solid',
          borderRadius: 1,
          alignItems: isDense ? 'center' : 'flex-start',
        },
        VARIANT_STYLES[variant],
        sx
      )}
    >
      {IconComponent && (
        <IconComponent
          flexShrink={0}
          size={20}
          sx={mixinSx(
            {
              paddingTop: isDense ? 0 : 0.5,
              color: VARIANT_ICON_COLOR[variant],
            },
            iconSx
          )}
        />
      )}
      {isDense ? (
        <>
          {title && (
            <Typography noWrap variant="h4">
              {title}
            </Typography>
          )}
          {description && (
            <Typography flexGrow={1} noWrap>
              {description}
            </Typography>
          )}
          {customAction}
          {showAction && (
            <Box flexShrink={0} height={32}>
              <ProgressButton
                variant="text"
                color="primary"
                {...actionButtonProps}
                isInProgress={!!actionInProgress}
              >
                {actionLabel}
              </ProgressButton>
            </Box>
          )}
        </>
      ) : (
        <Stack gap={1} flexGrow={1}>
          {title && (
            <Typography variant="h4" sx={{ textWrap: 'balance' }}>
              {title}
            </Typography>
          )}
          {description && <Box sx={{ textWrap: 'pretty' }}>{description}</Box>}
          {customAction}
          {showAction && (
            <Box sx={{ marginTop: 0.5 }}>
              <ProgressButton
                sx={{ flexShrink: 0 }}
                {...actionButtonProps}
                isInProgress={!!actionInProgress}
                color={actionButtonProps.color ?? 'secondary'}
              >
                {actionLabel}
              </ProgressButton>
            </Box>
          )}
        </Stack>
      )}
      {onDismiss && (
        <Box flexShrink={0}>
          <IconButton size="large" onClick={onDismiss}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
});

export function CalloutMarkdownWrapper({
  markdown,
}: { markdown: LocalizedString }) {
  return (
    <Box
      sx={{
        '& p:last-child, & ul:last-child li:last-child': {
          marginBottom: 0,
        },
        '& p': {
          marginTop: 0,
        },
      }}
    >
      <CustomReactMarkdown source={markdown} />
    </Box>
  );
}
